<template>
    <transition name="fade_custom" mode="out-in">
        <div class="drawer__content">
            <slot>static content</slot>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        uid: {
            // optional 'key' value for specifying a drawer by name (useful for parent)
            type: String,
        },
    },
    data() {
        return {
            isStatic: true,
            isActive: false,
            // isAvailable: this.$props.available,
        }
    },

    computed: {
        shown() {
            return this.available && this.isActive
        },
    },

    methods: {
        setActiveState(value) {
            if (typeof value != "boolean")
                throw Error(
                    `ToolDrawerContent: active state must be a boolean, provided ${typeof value}`
                )
            this.isActive = value
        },
    },
}
</script>

<style lang="scss" scoped>
// NEED TO ANIMATE HEIGHT/WIDTH CHANGES BASED ON ACTIVE & 'horiz' CLASS
.drawer__content {
    width: 100%;
    overflow: auto;
}

.fade_custom-enter-active,
.fade_custom-leave-active {
    transition: opacity $transition_speed;
}

.fade_custom-enter, .fade_custom-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    position: absolute;
}
</style>
