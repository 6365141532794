<template>
    <div id="status_wrapper">
        <transition enter-active-class="appear" leave-active-class="remove">
            <div
                class="status_content"
                v-if="hasStatus"
                v-bind:class="{
                    //'shown': (hasStatus),
                    warning: getStatus.warning,
                }"
            >
                <font-awesome class="status_icon load fa-spin" icon="spinner" />
                <font-awesome
                    class="status_icon warn"
                    icon="exclamation-triangle"
                />

                <div class="status_text">
                    <div class="text">{{ getStatus.text }}</div>
                </div>

                <font-awesome
                    title="Clear Warning"
                    @click="clearStatus()"
                    class="status_icon warn clear"
                    icon="times-circle"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name: "status",
    computed: {
        ...mapGetters(["hasStatus", "getStatus"]),
    },

    methods: {
        ...mapActions(["setStatus", "clearStatus"]),
    },

    // created() {
    //     // sample status for testing
    //     setTimeout( () => this.setStatus({text: "testing styling with a super long text string...", warning: true}), 2000)
    // },

    watch: {
        getStatus: function() {
            // potentially add auto hide functionality based on if text is null ()
        },
    },
}
</script>

<style lang="scss" scoped>
$background_default: #256eff;
$background_warning: #e67f0d;
$text_color: #fff;

.appear {
    // animation: $anim_fadein;
    animation: $anim_fadein;
}
.remove {
    // animation: $anim_fadeout;
    animation: $anim_flyout_R;
    animation-delay: $delay_slight; // let them read
}

// wrapper for status popups
#status_wrapper {
    // background: red;
    overflow: hidden;
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 3;
    max-width: 50%;

    display: flex;
    align-content: flex-end;
}

// status messages
.status_content {
    --status-color: #{$background_default};

    &.warning {
        --status-color: #{$background_warning};
    }

    display: flex;

    align-self: center;
    margin-left: auto;

    // opacity: 0;

    .status_text {
        display: inline-block;
        vertical-align: middle;
        background: var(--status-color);
        color: $text_color;
        font-size: 1rem;
        font-family: $header_font;
        border-radius: 0.35em;
        padding: 0.35em 0.5em;
    }

    .status_icon {
        color: var(--status-color);
        vertical-align: middle;
        margin: auto 0.2em;
        font-size: 1.5em;
    }

    .warn.clear {
        color: #941606;
        transition: all $transition_speed;

        &:hover {
            color: red;
        }
    }

    /* ICON DISPLAY */
    .status_icon {
        &.warn {
            display: none;
        }
        &.load {
            display: inline-block;
        }
    }

    &.warning {
        .status_icon {
            &.warn {
                display: inline-block;
            }
            &.load {
                display: none;
            }
        }
    }
}
</style>
