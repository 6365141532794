<template>
    <div id="app">
        <div id="header_wrapper">
            <Header />
        </div>

        <div id="content_wrapper">
            <div class="content">
                <div id="forecast_selection_wrapper">
                    <ForecastSelection />
                </div>

                <div id="display_wrapper">
                    <div id="map_wrapper">
                        <Map ref="map" />
                        <Status />
                        <!-- <div id="legend_container_wrapper">
                            <LegendContainer></LegendContainer>
                        </div> -->
                    </div>

                    <div id="sidebar_wrapper">
                        <ToolDrawer
                            ref="tooldrawer"
                            @update:expansion="update_expansion"
                            @update:selectedDrawer="update_drawer_selection"
                            :handlePosition="
                                this.useMobileLayout ? 'top' : 'left'
                            "
                            :selectedDrawer="this.SELECTED_DRAWER"
                            :expanded="this.isDrawerExpanded"
                        >
                            <ToolDrawerContent
                                :available="this.hasLayers"
                                uid="layers"
                                icon="layer-group"
                            >
                                <MapLayersList
                                    @update:layer_order="update_layer_order"
                                    @layer-toggled="layer_toggled_evt"
                                />
                            </ToolDrawerContent>

                            <!-- <ToolDrawerContent :available="this.isChartAvailable" uid="chart" icon="chart-line">
                                <Chart />
                            </ToolDrawerContent> -->

                            <ToolDrawerContent
                                :available="this.hasVisuals"
                                uid="visuals"
                                icon="chart-line"
                            >
                                <FeatureInfo :visuals="this.getVisuals" />
                            </ToolDrawerContent>

                            <ToolDrawerStaticContent style="flex-shrink: 0">
                                <LegendContainer></LegendContainer>
                            </ToolDrawerStaticContent>
                        </ToolDrawer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "./components/header"
import Map from "./components/map"
import MapLayersList from "./components/MapLayersList/MapLayersList"

import ForecastSelection from "./components/forecastSelection"
// import Chart from "./components/chart"    // IDEALLY this will be worked out of the application in favor of 'visuals'
import FeatureInfo from "./components/featureInfo"
import Status from "./components/status"

import ToolDrawer from "./components/ToolDrawer/ToolDrawer"
import ToolDrawerContent from "./components/ToolDrawer/ToolDrawerContent"
import ToolDrawerStaticContent from "./components/ToolDrawer/ToolDrawerStaticContent"

import LegendContainer from "./components/Legends/LegendContainer"

import { mapGetters, mapActions } from "vuex"

export default {
    name: "App",

    components: {
        Header,
        Map,
        ForecastSelection,
        // Chart,
        FeatureInfo,
        Status,
        ToolDrawer,
        ToolDrawerContent,
        ToolDrawerStaticContent,
        MapLayersList,
        LegendContainer,
    },

    data() {
        return {
            MOBILE_WIDTH_THRESHOLD_PX: 800,
            // USE_MOBILE: true,
            SELECTED_DRAWER: -1,
        }
    },

    computed: {
        ...mapGetters([
            "isDrawerExpanded",
            "isChartAvailable",
            "hasLayers",
            "getVisuals",
            "hasVisuals",

            "useMobileLayout",

            // flood model
            "getSelectedForecast",
            "getSelectedFloodModelFeature",

            // mrms
            "getMRMSMeta",

            // NWIS
            "getNWISMetadata",
        ]),
    },

    methods: {
        ...mapActions([
            "setDrawerExpansion",
            "setMobileLayout",

            // mrms
            "fetchMRMSMeta",

            // NWIS
            // 'fetchNWISDailyValuesMeta',
            // 'fetchNWISDailyValues',
        ]),

        update_layer_order(id_list) {
            // console.log('hit it')
            this.$refs["map"].resort_layers(id_list)
        },

        update_mobile() {
            // this.USE_MOBILE = window.matchMedia(`(max-width: ${this.MOBILE_WIDTH_THRESHOLD_PX}px)`).matches

            // this.setMobileLayout(this.USE_MOBILE)
            this.setMobileLayout(
                window.matchMedia(
                    `(max-width: ${this.MOBILE_WIDTH_THRESHOLD_PX}px)`
                ).matches
            )
        },

        update_expansion(value) {
            this.setDrawerExpansion(value)
            this.$nextTick(this.$refs["map"].window_resize_evt)
        },

        update_drawer_selection(value = -1) {
            this.SELECTED_DRAWER = value
            // close if no selection
            if (value == -1) this.close_drawer()
            else this.auto_expand_drawer()
        },

        close_drawer() {
            this.setDrawerExpansion(false)
        },

        // expand the drawer if not in mobile display (to avoid claiming the entire screen on touch)
        auto_expand_drawer() {
            if (!this.useMobileLayout) this.setDrawerExpansion(true)
        },

        set_active_drawer_by_uid(uid) {
            let drawer_id = this.$refs["tooldrawer"].get_uid_index(uid)
            this.update_drawer_selection(drawer_id)
        },

        // notify the map of updated layers
        layer_toggled_evt(payload) {
            this.$refs["map"].set_layer_visibility(payload.id, payload.visible)
        },

        // base_layer_selected_evt(payload){
        // this.$refs['map'].update_base_layer()
        // }
    },

    watch: {
        SELECTED_DRAWER: function() {
            // if no selected drawer, close
            if (this.SELECTED_DRAWER == -1) {
                this.close_drawer()
                return
            }

            // auto-expand in desktop view
            this.auto_expand_drawer()
        },

        // 'isChartAvailable': function(){

        // if(this.isChartAvailable) this.set_active_drawer_by_uid('chart')
        // else if(this.hasLayers) this.set_active_drawer_by_uid('layers')
        // else this.close_drawer()
        // },

        // 'getSelectedForecast': function(){
        // if(this.isChartAvailable) this.set_active_drawer_by_uid('chart')

        // },

        // 'getSelectedFloodModelFeature': function(){
        // if(this.isChartAvailable) this.set_active_drawer_by_uid('chart')
        // },

        hasLayers: function() {
            if (this.hasLayers) this.set_active_drawer_by_uid("layers")
            else this.update_drawer_selection()
        },

        getVisuals: function() {
            if (this.hasVisuals) this.set_active_drawer_by_uid("visuals")
            else if (this.hasLayers) this.set_active_drawer_by_uid("layers")
            else this.close_drawer()
        },
    },

    created() {
        // perform some data fetches to get other
        this.fetchMRMSMeta()
    },

    mounted() {
        window.addEventListener("resize", this.update_mobile)
        this.update_mobile()
    },
    destroyed() {
        window.removeEventListener("resize", this.update_mobile)
    },
}
</script>

<style lang="scss">
// import the base style including browser reset
@import "@/styles";
@import "@/styles/layout.scss";
</style>
