<template>
    <div
        :data-uid="this.item.uid"
        :class="['map_layer_list_item_wrapper', { enabled: this.item.visible }]"
    >
        <div
            title="Toggle Layer Visiblity"
            :class="['layer_toggle']"
            @click="toggle_visibility_evt"
        >
            <font-awesome
                v-if="this.item.visible"
                class="visibility_icon"
                icon="eye"
            />
            <font-awesome v-else class="visibility_icon" icon="eye-slash" />
        </div>
        <div class="layer_glyph">
            <LayerGlyph :item="this.item" />
        </div>
        <div class="layer_name handle">
            <slot></slot>
        </div>

        <div
            title="Toggle Map Feature Selection"
            @click="toggle_layer_selection"
            v-if="this.item.selectable"
            :class="[
                'layer_selection_toggle',
                { enabled: this.item.selection_enabled },
            ]"
        >
            <font-awesome class="visibility_icon" icon="map-marked-alt" />
        </div>
    </div>
</template>

<script>
import { Layer } from "@/scripts/structures/layer"
import LayerGlyph from "./MapLayersGlyph"

export default {
    name: "MapLayersListItem",
    props: {
        item: {
            type: Layer,
        },
    },
    components: {
        LayerGlyph,
    },
    // mounted() {
    //     // this.isVisible = this.$props.visible;

    // },

    // data() {
    //     return {
    //         // UniqueID: null,
    //         // isVisible: false,
    //     }
    // },

    methods: {
        toggle_visibility_evt() {
            // this.isVisible = !this.isVisible;
            this.item.visible = !this.item.visible
            this.$emit("layer-toggled", {
                id: this.item.uid,
                visible: this.item.visible,
            })
        },

        toggle_layer_selection() {
            if (this.item.selectable) {
                this.item.selection_enabled = !this.item.selection_enabled
                this.$emit("layer-selection-toggled", {
                    id: this.item.uid,
                    visible: this.item.selection_enabled,
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
$border_color: rgba(0, 0, 0, 0.5);
$item_enabled_color: rgba(255, 255, 255, 0.15);

$icon_enabled_color: rgb(255, 255, 255);
$icon_disabled_color: rgba(255, 255, 255, 0.5);

$toggle_enabled_color: rgba(74, 182, 175, 0.5);
$toggle_hover_color: rgba(122, 129, 129, 0.5);
$toggle_disabled_color: rgba(0, 0, 0, 0.5);

$button_size: 50px;

.map_layer_list_item_wrapper {
    @include shadow;
    display: flex;
    flex-direction: row;
    width: 100%;
    // min-width: 400px;
    // margin: .2em;
    margin-bottom: 0.2em;
    border-radius: 0.75em;

    border: 1px solid $border_color;

    .handle {
        position: relative;
        cursor: pointer;

        &:hover {
            // background: red;
            transition: all 0.2s;
            &::after {
                content: "\2195";
                position: absolute;

                display: flex;
                align-items: center;
                justify-content: center;

                width: 2em;
                height: 100%;
                top: 0;
                right: 0;
                font-size: 1.2em;
                text-align: center;
                vertical-align: middle;
                background: $trans_darken;
            }
        }
    }

    .layer_toggle {
        cursor: pointer;
        // padding: 1em;
        border-right: 1px solid $border_color;
        // height: $button_size;
        width: $button_size;

        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;

        border-radius: 0.75em;
        transition: all 0.2s;

        background: $toggle_disabled_color;
        color: $icon_disabled_color;

        &:hover {
            background: $toggle_hover_color;
        }
    }

    .layer_name {
        flex: 1;
        margin: auto;
        padding: 0.75em 0.5em;
        font-size: 1.1em;
        // white-space: nowrap;
    }

    .layer_glyph {
        align-self: center;
        margin: 0.5em;
    }

    .layer_selection_toggle {
        cursor: pointer;
        padding: 0.2em;
        color: #fff;
        // border: 1px solid #fff;
        // background: #333;
        align-self: center;
        border-radius: 0.25em;
        margin: 0.5em;

        transition: all $transition_speed;

        &:hover {
            background: $trans_lighten;
        }

        &.enabled {
            color: #333;
            background: white;
            @include shadow_dark;
        }
    }

    &.enabled {
        // background: #fff;
        background: $item_enabled_color;
        .layer_toggle {
            background: $toggle_enabled_color;
            color: $icon_enabled_color;
        }
    }
}

@include phone-only {
    .map_layer_list_item_wrapper {
        .layer_name {
            font-size: 0.9em;
            font-weight: 600;
            // white-space: nowrap;
        }
    }
}
</style>
