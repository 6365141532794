import Vuex from "vuex"
import Vue from "vue"

import interface_model from "./modules/interface_model"
import flood_model from "./modules/flood_model"
import NWIS_model from "./modules/NWIS_model"
import MRMS_model from "./modules/MRMS_model"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        interface_model,
        flood_model,
        NWIS_model,
        MRMS_model,
    },
})
