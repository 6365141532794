import { Object_To_URL_PARAMS } from "@/scripts/url_helpers"
import { Layer } from "@/scripts/structures/layer"

const BASE_URL = "https://waterservices.usgs.gov/nwis/site/"
const NWIS_LAYER_METADATA = {
  type: "point",
  point_type: "square",
  color: "#ff0000",
  // fill_color: '#00ff00',
  opacity: 0.5,
  width: 3
}

// const METRIC_META = {
//     //"Elevation above NGVD 1929, ft"
//     // "Gage height, ft"
//     //
//     "streamflow":{
//         chart_buffer: 100,
//         chart_type: 'line',
//         units: 'cubic feet/second',
//         unit_abbrv: `ft3/s`,
//         display: 'Streamflow',
//     },
// }

function generate_url(bbox) {
  let params = {
    format: "ge,1.0",
    // bBox: '-83.000000,36.500000,-81.000000,38.500000',
    bBox: bbox, //'-83.000000,36.500000,-81.000000,38.500000',
    siteType: "ST",
    // siteStatus: 'all',
    siteStatus: "active",
    hasDataTypeCd: "iv,dv,aw"
  }

  return `${BASE_URL}?${Object_To_URL_PARAMS(params)}`
}

let NWIS_LAYER = function({
  // time,
  bbox,
  // projection,
  addtl_layer_config = {}
}) {
  // if(!time) throw Error('NWIS_LAYER: a time parameter is expected for Layer generation!')
  if (!bbox)
    throw Error(
      "NWIS_LAYER: a bbox parameter is expected for Layer generation!"
    )
  // if(!projection) throw Error('NWIS_LAYER: a projection parameter is expected for Layer generation!')

  // format bbox slightly to reduce decimal to 6 places
  let formatted_bbox = bbox.map(e => e.toFixed(6))

  let url = generate_url(formatted_bbox)

  return new Layer({
    ...addtl_layer_config,
    selectable: true,
    selection_enabled: true,
    type: "kml",
    metadata: NWIS_LAYER_METADATA,
    url
  })
}

export default NWIS_LAYER
