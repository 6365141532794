/**
 * converts an object of key/value pairs to a url param string
 * @param {object} obj
 * @returns {string} a string representation of url params, e.g. 'key1=value1&key2=value2 ... '
 */
function Object_To_URL_PARAMS(obj) {
    return Object.keys(obj)
        .map((key) => key + "=" + obj[key])
        .join("&")
}

export { Object_To_URL_PARAMS }
