/**
 * Object representing visualizations to be presented in the 'feature_info' container
 *
 * needs methods of providing collection of 'markup' and 'series/chart configs'
 */
window.VISUALIZATION_ID = 0
window.NEW_VISUALIZATION_ID = function() {
    return window.VISUALIZATION_ID++
}

class Visual {
    constructor() {
        this.uid = window.NEW_VISUALIZATION_ID()
    }
}

class Markup_visual extends Visual {
    constructor({ template }) {
        super()
        this.template = template
    }

    wrapped_html() {
        var dom = document.createElement("div")
        dom.innerHTML = this.template
        return dom
    }
}

class Chart_tool {
    constructor({ title, icon, callback }) {
        this.title = title
        this.icon = icon
        this.callback = callback
    }
}

class Chart_visual extends Visual {
    constructor({ title, subtitle, options, series, height, tools }) {
        super()
        // this.uid = window.NEW_VISUALIZATION_ID()
        this.title = title
        this.subtitle = subtitle
        this.options = options
        this.series = series
        this.height = height
        this.tools = tools
    }
}

export default {
    Markup_visual,
    Chart_visual,
    Chart_tool,
}
