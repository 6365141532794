var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"map_layer_list_wrapper"}},[_c('div',{staticClass:"base_layer_selection_wrapper"},[_c('span',{staticClass:"base_layer_selection_label"},[_vm._v("Map Background")]),_c('div',{staticClass:"base_layers"},_vm._l((_vm.getBaseLayers),function(layer){return _c('div',{key:layer.name,class:[
                    'base_layer',
                    { selected: _vm.getSelectedBaseLayer == layer.name } ],attrs:{"data-layer-name":layer.name,"title":layer.title},on:{"click":function($event){return _vm.update_base_layer_evt(layer.name)}}},[[(layer.display && layer.display.type == 'img')?_c('img',{staticClass:"image",attrs:{"src":_vm.getImgUrl(layer.display.path)}}):_vm._e(),(layer.display && layer.display.type == 'icon')?_c('font-awesome',{staticClass:"icon",attrs:{"icon":layer.display.icon}}):_vm._e()]],2)}),0)]),_c('div',{staticClass:"map_layer_list_container"},[_c('draggable',_vm._b({attrs:{"group":"layer_groups"},on:{"start":_vm.drag_start_evt,"end":_vm.drag_end_evt}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.dragging ? 'flip-list' : null}},[_vm._l((this.layer_groups),function(group){return [_c('div',{key:group,class:[
                            'group_wrapper',
                            { hidden: _vm.group_hidden(group) } ],attrs:{"data-group":group}},[(group)?_c('div',{staticClass:"group_header handle",on:{"click":_vm.toggleGroup}},[_vm._v(" "+_vm._s(group)+" "),_c('font-awesome',{staticClass:"icon",attrs:{"icon":_vm.group_hidden(group)
                                        ? 'angle-down'
                                        : 'angle-up'}})],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"group_items",attrs:{"data-group":group}},[_c('draggable',_vm._b({attrs:{"group":group
                                            ? (group + "_layer")
                                            : 'layer_groups'},on:{"start":_vm.drag_start_evt,"end":_vm.drag_end_evt}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.dragging ? 'flip-list' : null}},[_vm._l((_vm.layers_in_group(
                                                group
                                            )),function(layer){return [(_vm.isLayer(layer))?_c('ListItem',{key:layer.uid,attrs:{"item":layer},on:{"layer-toggled":_vm.childToggledEvent}},[_vm._v(" "+_vm._s(layer.name)+" ")]):_vm._e()]})],2)],1)],1)])],1)]})],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }