<template>
    <!-- Ideally this container will be
         a hybrid colapsible/tab container positioning
         allow for single drawer
         specify cardinal fly-out direction
         support in-place or relative positioning (parent container so non-included)

  -->
    <div
        class="drawer"
        :class="[
            this.handlePosition,
            {
                // conditionally mark horizontal
                horiz: ['left', 'right'].indexOf(handlePosition) !== -1,
            },
            {
                disabled: !this.anyDrawerAvailable || this.disabled,
            },
            this.getSelectedForecastMode == 'current' ? '' : 'alt',
        ]"
    >
        <div
            class="drawer__handle"
            :class="{
                active: this.expanded,
            }"
            @click="expand_clicked_evt"
        >
            <div class="drawer__icons">
                <!-- SHOULD TAKE INTO ACCOUNT SINGLE DRAWER 
                    Also skip over static drawers 
                -->
                <div
                    v-for="(drawer, index) in drawers.filter(
                        (drawer) => !drawer.isStatic
                    )"
                    :key="index"
                    :class="[
                        'drawer__icon',
                        { active: index == currentDrawer },
                        { disabled: !drawer.available },
                    ]"
                    @click="drawer_icon_clicked($event, index)"
                >
                    <font-awesome
                        v-if="drawer.icon != ''"
                        :icon="drawer.icon"
                    ></font-awesome>
                    <div v-else-if="drawer.label">
                        {{ drawer.label }}
                    </div>
                </div>
            </div>
        </div>
        <!-- hide child drawers when inactive -->
        <!-- <transition name="shrink" mode="out-in"> -->
        <div class="drawer__content_wrapper" v-show="this.expanded">
            <slot></slot>
        </div>
        <!-- </transition> -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        expanded: Boolean,
        selectedDrawer: {
            type: Number,
            default: 0,
        },
        handlePosition: {
            type: String,
            default: "top",
            validator: function(value) {
                // The value must match one of these strings
                return ["top", "bottom", "left", "right"].indexOf(value) !== -1
            },
        },
    },

    data() {
        return {
            // isDisabled: false,
            // isExpanded: false,
            // selectedDrawer: -1, // the index of the selected tab,

            // anyDrawerAvailable: false, // if any of the child drawers are 'available'

            drawers: [], // all of the drawers
        }
    },

    computed: {
        ...mapGetters(["getSelectedForecastMode"]),
        currentDrawer() {
            return this.selectedDrawer
        },

        anyDrawerAvailable() {
            return this.drawers.some((drawer) => drawer.available)
        },

        availableDrawerIndexes() {
            return this.drawers
                .map((drawer, i) => i)
                .filter((drawer, i) => drawer.available)
        },

        expandable() {
            return !this.disabled && this.anyDrawerAvailable
        },
    },

    created() {
        this.drawers = this.$children
        // this.isDisabled = this.$props.disabled
        // this.isExpanded = this.$props.expanded
        // this.selectedDrawer = this.$props.selectedID
    },

    mounted() {
        this._update_drawers()
    },

    methods: {
        // **********************************
        // INTERNAL METHODS
        // **********************************
        _update_drawers() {
            // set the current active drawer, deactivate others
            this.drawers.forEach((drawer, index) => {
                // if (drawer.isStatic) {
                //     return
                // }

                if (drawer.setActiveState) {
                    drawer.setActiveState(index === this.selectedDrawer)
                    return
                }
            })
        },

        // **********************************
        // COMPONENT UTILITY METHODS
        // **********************************

        get_uid_index(uid) {
            return this.drawers.findIndex((drawer) => drawer.uid == uid)
        },

        // **********************************
        // CLICK EVENTS/EMITTERS
        // **********************************
        expand_clicked_evt(evt) {
            if (this.expandable) this.$emit("update:expansion", !this.expanded)
        },

        drawer_icon_clicked(evt, i) {
            evt.stopPropagation()

            if (this.drawers[i] && this.drawers[i].available) {
                if (!this.expanded) this.$emit("update:expansion", true)
                if (i == this.selectedDrawer) return

                this.$emit("update:selectedDrawer", i)
            }
        },
    },

    watch: {
        // if the selected drawer property changes update the active drawer
        selectedDrawer() {
            this._update_drawers()
        },

        expanded: function() {
            // console.log("expanded changed")
            this.$emit("update:expansion", this.expanded)
        },
    },
}
</script>

<style lang="scss" scoped>
// structural styling

.drawer {
    flex-grow: 1;

    &.disabled {
        .drawer__handle {
            cursor: not-allowed;
        }
    }

    &:not(.disabled) {
        .drawer__handle {
            cursor: pointer;
        }
    }

    .drawer__icon {
        display: flex;
        align-items: center;
        align-self: center;
        text-align: center;

        svg {
            margin: auto;
        }
    }

    .drawer__handle {
        display: flex;
        // align-items: center;
        padding: 0.5em;

        .drawer__icon {
            cursor: pointer;
            padding: 0.25em 0.5em;
            margin: 0.5em;
            // align-self: stretch;

            &.disabled {
                cursor: not-allowed;
            }
        }

        .drawer__icons {
            display: flex;

            // margin:.2em .5em;
        }
    }

    .drawer__content_wrapper {
        display: flex;
        flex-grow: 1;
        position: relative;
        flex-direction: column;
    }

    &.horiz {
        flex-direction: row;

        .drawer__handle {
            display: inline-flex;
            flex-direction: column;
            // min-height: 300px;
        }

        .drawer__icons {
            flex-direction: column;

            // .drawer__icon {
            //   // flex:1;
            //   // align-self: stretch;

            // }
        }

        .drawer__content_wrapper {
            // display: inline-flex;
            display: flex;
            flex-direction: column;

            .drawer__content {
                flex-grow: 1;
            }
        }
    }

    .drawer__content_wrapper {
        overflow: hidden;
    }

    // ANIMATIONS
    .shrink-enter-active,
    .shrink-leave-active {
        transition: all $transition_speed;
    }

    &:not(.horiz) {
        .shrink-enter, .shrink-leave-to /* .fade-leave-active below version 2.1.8 */ {
            height: 0;
        }
    }
    &.horiz {
        // TODO: not quite...

        .shrink-enter, .shrink-leave-to /* .fade-leave-active below version 2.1.8 */ {
            width: 0;
        }
    }
}

// color styling
$transition_speed: 0.3s;

$handle_color: rgb(51, 51, 51);
$handle_color_hover: rgb(85, 85, 85);
// $handle_color_active:rgb(0, 156, 196);
$handle_color_active: rgb(148, 22, 6);
$handle_color_disabled: rgb(85, 85, 85);

$icon_toolbar_back: rgba(255, 255, 255, 0.1);

// svg/text color
$icon_size: 1.5em;
$icon_color: rgb(255, 255, 255);
$icon_color_active: rgb(51, 51, 51);
$icon_color_hover: rgb(255, 255, 255);
$icon_color_disabled: rgb(180, 180, 180);

// wrapper colors
$icon_back_color: transparent;
$icon_back_color_hover: rgba(255, 255, 255, 0.5);
$icon_back_color_active: rgb(255, 255, 255);
$icon_rounding: 0.25em;

$content_background: $background_veryDark;
$content_text_color: white;

.drawer {
    // alternate default color based on app state
    --drawer-handle-color-active: var(--app-primary-color);

    &.alt {
        --drawer-handle-color-active: var(--app-primary-color-alt);
    }

    display: flex;
    flex-direction: column;

    .drawer__handle {
        padding: 0.5em;
        background-color: $handle_color;
        transition: all $transition_speed;
        border: 1px solid $trans_darken;

        .drawer__icon {
            transition: all $transition_speed;
            font-size: $icon_size;
            color: $icon_color;
            background-color: $icon_back_color;
            border-radius: 0.25em;

            &.disabled {
                color: $icon_color_disabled;
            }

            &:not(.disabled) {
                &.active {
                    color: $icon_color_active;
                    background-color: $icon_back_color_active;
                    @include shadow;
                }

                &:hover {
                    color: $icon_color_hover;
                    background-color: $icon_back_color_hover;
                }
            }
        }

        .drawer__icons {
            background-color: $icon_toolbar_back;
            border-radius: 0.5em;
        }

        &.active {
            // background-color: $handle_color_active;
            background-color: var(--drawer-handle-color-active);

            .drawer__icons {
                .drawer__icon {
                    transition: all $transition_speed;
                    border-radius: 0.25em;

                    &.selected {
                        color: $icon_color_active;
                        background-color: $icon_back_color_active;
                    }
                }
            }
        }

        &:hover {
            background-color: $handle_color_hover;
        }
    }

    .drawer__content_wrapper {
        background: $content_background;

        color: $content_text_color;
        // border: 1px solid rgba(0,0,0,.2);
        // padding: .5em;
    }
}

$drawer_content_width: $sidebar_width;
$drawer_content_min_width: $sidebar_min_width;
@include desktop-only {
    .drawer {
        // --drawer-preference-width: #{$sidebar_preference_width};
        // &.large {
        //      --drawer-preference-width: 50vw;
        // }

        .drawer__content_wrapper {
            // width: var(--drawer-preference-width);
            width: $drawer_content_width;
            min-width: $drawer_content_min_width;
        }
    }
}

@include phone-only {
    .drawer {
        .drawer__handle {
            &::after {
                content: "";
                position: absolute;
                right: 0;
                margin: 1em;
                height: 20px;
                width: 20px;

                background-image: url("../../assets/chevron-arrow-down.svg");
                background-position: top left;
                background-size: contain;
                transform: rotate(180deg);
                transition: all $transition_speed;
            }
            &.active {
                &::after {
                    transform: rotate(0deg);
                }
            }
        }
    }
}
</style>
