/**
 * Module providing a Layer object type to be used throughout the Application
 */

// TODO: need to add in a 'global' uid generator to alleviate need for uid being provided

class Layer {
    constructor({
        uid = null,
        name = "",
        url = "",
        type = "",
        group = "",
        metadata = {},
        visible = true,
        selectable = false,
        selection_enabled = false,

        legend = null,
    }) {
        this._config = {
            uid,
            name,
            url,
            type,
            group,
            metadata,
            visible,
            selectable,
            legend,
        }
        if (this._config.selectable)
            this._selection_enabled = selection_enabled || false
    }

    get uid() {
        if (!this._group && !this._config.uid)
            console.warn(
                `Layer: Layer '${this._config.name}' does not have an id provided!`
            )
        return this._config.uid
    }

    get group() {
        return this._config.group
    }

    get name() {
        return this._config.name
    }

    get url() {
        return this._config.url
    }
    get type() {
        return this._config.type
    }

    get metadata() {
        return this._config.metadata
    }

    get visible() {
        return this._config.visible
    }
    set visible(new_val) {
        this._config.visible = new_val

        // if (this._config.visible) {

        // }
    }

    get selectable() {
        return this._config.selectable
    }

    get selection_enabled() {
        return this._selection_enabled || false
    }

    set selection_enabled(new_val) {
        if (this.selectable) this._selection_enabled = new_val
    }
    // set selectable(new_val){
    //     this._config.selectable = new_val
    // }

    get legend() {
        return this._config.legend ?? null
    }
}

/* possibly add a 'layer collection' object  */

class LayerCollection {
    constructor({ layer_collection, name = "", uid = null }) {
        if (uid == null)
            throw Error("LayerCollection: uid option must be provided!")
        if (!_valid_layer_collection(layer_collection))
            throw Error(
                "LayerCollection: Invalid layer collection provided to constructor, expected Array of Layer instances"
            )
        this._config = {
            uid,
            name,
            layers: layer_collection,
        }

        this._config.layer_collection = _update_collection(this)
    }
    get uid() {
        return this._config.uid
    }

    get name() {
        return this._config.name
    }

    get layers() {
        return this._config.layers
    }
}

/**
 * updates LayerCollection's child Layers to have collection overrides for id, name, etc.
 * @param {Layer[]} layer_collection
 */
function _update_collection(layer_collection) {
    layer_collection.layers.forEach((layer, index) => {
        layer._group = this
        layer._config.uid = `${layer_collection.uid}_${index}`
    })
    return layer_collection
}

function _valid_layer_collection(collection) {
    if (!(collection instanceof Array)) return false
    if (!collection.every((elem) => elem instanceof Layer)) return false

    return true
}

export { Layer, LayerCollection }
