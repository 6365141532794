!<template>
    <div v-if="this.item" class="map_layer_glyph_wrapper"></div>
</template>

<script>
import { Layer } from "@/scripts/structures/layer"
import { SVG } from "@svgdotjs/svg.js"

// docs for the SVG library
// https://svgjs.com/docs/3.0/shape-elements/#svg-polygon

export default {
    name: "MapLayersGlyph",
    props: {
        item: {
            type: Layer,
        },
    },

    data() {
        return {
            ICON_SIZE: 20, // NOTE: if this changes the height/width on wrapper must as well
        }
    },
    computed: {
        glyph_size() {
            return this.ICON_SIZE - 5
        },
    },

    mounted() {
        // only generate glyph for locally generated styles (potentially add option to pass it later)
        if (this.item.metadata && this.item.metadata.type != "external") {
            this.generate_svg(this.item.metadata)
        }
    },

    methods: {
        _circlePoints(pointsCount, r, offset) {
            const points = []
            let offsetDeg = (offset * Math.PI * 2) / 360
            let baseAngle = Math.PI / 2 + offsetDeg
            let intervalDeg = ((360 / pointsCount) * Math.PI * 2) / 360
            for (let i = 0; i < pointsCount; i++) {
                points.push({
                    x: r * Math.cos(baseAngle + intervalDeg * i),
                    y: r * Math.sin(baseAngle + intervalDeg * i),
                })
            }

            return points
        },

        _calculate_star_points(size) {
            let radius = size * 0.5
            // translate points toward center of svg canvas (down & right)
            // get 5-points on circle
            let outer_points = this._circlePoints(5, radius, -36).map(
                (point) => {
                    return {
                        x: point.x + radius,
                        y: point.y + radius,
                    }
                }
            )

            // get 5-points on smaller circle offset by 1/5 largers radius
            let inner_points = this._circlePoints(5, radius * 0.5, 0).map(
                (point) => {
                    return {
                        x: point.x + radius,
                        y: point.y + radius,
                    }
                }
            )

            // interwieve points together
            let star_points = []
            outer_points.forEach((point, index) => {
                star_points.push(`${point.x},${point.y}`)
                star_points.push(
                    `${inner_points[index].x},${inner_points[index].y}`
                )
            })

            return star_points.join(" ")
        },

        _polygon_points(num_points, size) {
            let radius = size * 0.5
            let points = this._circlePoints(
                num_points,
                radius,
                (360 / num_points / 2) * -1
            ).map((point) => {
                return {
                    x: point.x + radius,
                    y: point.y + radius,
                }
            })

            // stringify points into array and return points string
            return points.map((point) => `${point.x},${point.y}`).join(" ")
        },

        // generates a weird abstract shape to use on 'shape' layers
        _area_layer_points(size) {
            let radius = size * 0.5

            let outer_points = this._circlePoints(5, radius, -80).map(
                (point) => {
                    return {
                        x: point.x + radius,
                        y: point.y + radius,
                    }
                }
            )

            // get 5-points on smaller circle offset by 1/5 largers radius
            let inner_points = this._circlePoints(5, radius * 0.2, -30).map(
                (point) => {
                    return {
                        x: point.x + radius,
                        y: point.y + radius,
                    }
                }
            )

            // interwieve points together
            let poly_points = [
                ...outer_points.map((point) => `${point.x},${point.y}`),
                ...inner_points
                    .slice(0, 1)
                    .map((point) => `${point.x},${point.y}`),
            ]

            return poly_points.join(" ")
        },

        generate_svg(layer_meta) {
            // if the necessary metadata fields are not provided return
            if (!(layer_meta.type || layer_meta.color)) return
            // console.log(`generating SVG for ${this.item.name}`)

            // generate icon representing the layer from provided metadata
            let icon = SVG()
                .size(this.ICON_SIZE, this.ICON_SIZE)
                .addTo(this.$el)

            let COLOR = layer_meta.color
            let FILL_COLOR = layer_meta.fill_color || layer_meta.color
            // let WIDTH = layer_meta.width ?? 2;
            let WIDTH = 2
            let OPACITY = layer_meta.opacity ?? 0.5

            // console.log(WIDTH)
            let color_config = {
                fill: COLOR,
                "fill-opacity": OPACITY,
                stroke: FILL_COLOR,
                "stroke-width": WIDTH,
            }

            // ADD APPROPRIATE SVG SHAPES TO THE ICON
            switch (layer_meta.type) {
                case "point":
                    // generate individual point types
                    switch (layer_meta.point_type) {
                        case "square":
                            icon.polygon(
                                this._polygon_points(4, this.glyph_size)
                            )
                                .move(WIDTH, WIDTH)
                                .attr(color_config)
                            break
                        case "star":
                            icon.polygon(
                                this._calculate_star_points(this.glyph_size)
                            )
                                .move(WIDTH, WIDTH)
                                .attr(color_config)
                            break
                        case "triangle":
                            icon.polygon(
                                this._polygon_points(3, this.glyph_size)
                            )
                                .move(WIDTH, WIDTH)
                                .attr(color_config)
                            break

                        default:
                            //circle
                            icon.circle(this.glyph_size, this.glyph_size)
                                .attr(color_config)
                                .move(WIDTH, WIDTH)
                            break
                    }

                    break

                case "polygon":
                    // icon.rect(this.glyph_size,this.glyph_size).attr(color_config)
                    icon.polygon(this._area_layer_points(this.glyph_size))
                        .attr(color_config)
                        .move(WIDTH, WIDTH)
                    break

                case "line":
                    // line points are origin and bottom right corner
                    icon.line(0, 0, this.glyph_size, this.glyph_size).attr(
                        color_config
                    )
                    icon.line(
                        this.glyph_size / 2,
                        0,
                        this.glyph_size / 2,
                        this.glyph_size / 2
                    ).attr(color_config)
                    break

                default:
                    // icon.rect(this.glyph_size,this.glyph_size).attr(color_config).move(WIDTH, WIDTH)
                    break
            }

            // center the shape in the wrapper
            // icon.move(50,50)
        },
    },
}
</script>

<style lang="scss" scoped>
.map_layer_glyph_wrapper {
    display: flex;
    height: 20px;
    width: 20px;
}
</style>
