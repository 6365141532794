<template>
    <!-- <div
        v-if="isText(this.item.legend.template)"
        class="legend_item"
        v-html="this.item.legend.template"
    ></div>
    <div v-else class="legend_item">{{ this.item.legend.template }}</div> -->

    <div class="legend_item"></div>
</template>

<script>
import { Layer } from "@/scripts/structures/layer"

export default {
    name: "LegendItem",

    props: {
        item: {
            required: true,
            type: Layer,
        },
    },
    mounted() {
        if (this.isText(this.item.legend.template)) {
            this.$el.innerHTML = this.item.legend.template
        } else {
            console.log(this.$el)
            this.$el
                // .querySelector(".legend_item")
                .append(this.item.legend.template)
        }
    },

    computed: {
        legend() {
            console.log(typeof this.item.legend.template)
            return this.item.legend.template
        },
    },

    methods: {
        isText(val) {
            return typeof this.item.legend.template == "string"
        },
        isElement(obj) {
            return obj instanceof HTMLElement
        },
    },
}
</script>

<style lang="scss" scoped>
.legend_item {
    border-radius: 0.25em;
    background: $trans_darken;
    // margin: 0.2em;
    padding: 0.5em;
    margin-bottom: 0.5em;

    &:last-child {
        margin-bottom: 0;
    }
}
</style>
