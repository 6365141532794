import Visuals from "@/scripts/structures/visualizations"

// HRRR color ramp from: https://mesonet.agron.iastate.edu/GIS/rasters.php?rid=2
let HRRR_ramp = [
    { label: "ND", color: "#000000" },
    { label: "-32", color: "#85718f" },
    { label: "-31.5", color: "#85728f" },
    { label: "-31", color: "#86738d" },
    { label: "-30.5", color: "#87758b" },
    { label: "-30", color: "#87768b" },
    { label: "-29.5", color: "#887789" },
    { label: "-29", color: "#897987" },
    { label: "-28.5", color: "#897a87" },
    { label: "-28", color: "#8a7b85" },
    { label: "-27.5", color: "#8b7d84" },
    { label: "-27", color: "#8b7e84" },
    { label: "-26.5", color: "#8c7f82" },
    { label: "-26", color: "#8d8180" },
    { label: "-25.5", color: "#8d8280" },
    { label: "-25", color: "#8e837e" },
    { label: "-24.5", color: "#8f847c" },
    { label: "-24", color: "#8f857c" },
    { label: "-23.5", color: "#90877b" },
    { label: "-23", color: "#918879" },
    { label: "-22.5", color: "#918979" },
    { label: "-22", color: "#928b77" },
    { label: "-21.5", color: "#938d75" },
    { label: "-21", color: "#969153" },
    { label: "-20.5", color: "#989457" },
    { label: "-20", color: "#9b975b" },
    { label: "-19.5", color: "#9d9a60" },
    { label: "-19", color: "#a09d64" },
    { label: "-18.5", color: "#a3a068" },
    { label: "-18", color: "#a5a36d" },
    { label: "-17.5", color: "#a8a671" },
    { label: "-17", color: "#aaa976" },
    { label: "-16.5", color: "#adac7a" },
    { label: "-16", color: "#b0af7e" },
    { label: "-15.5", color: "#b2b283" },
    { label: "-15", color: "#b7b88c" },
    { label: "-14.5", color: "#babb90" },
    { label: "-14", color: "#bdbe94" },
    { label: "-13.5", color: "#bfc199" },
    { label: "-13", color: "#c2c49d" },
    { label: "-12.5", color: "#c4c7a2" },
    { label: "-12", color: "#c7caa6" },
    { label: "-11.5", color: "#cacdaa" },
    { label: "-11", color: "#ccd0af" },
    { label: "-10.5", color: "#d2d4b4" },
    { label: "-10", color: "#cfd2b4" },
    { label: "-9.5", color: "#c9ccb4" },
    { label: "-9", color: "#c6c9b4" },
    { label: "-8.5", color: "#c3c7b4" },
    { label: "-8", color: "#c0c4b4" },
    { label: "-7.5", color: "#bdc1b4" },
    { label: "-7", color: "#b9beb4" },
    { label: "-6.5", color: "#b6bbb4" },
    { label: "-6", color: "#b3b9b4" },
    { label: "-5.5", color: "#b0b6b4" },
    { label: "-5", color: "#adb3b4" },
    { label: "-4.5", color: "#aab0b4" },
    { label: "-4", color: "#a4abb4" },
    { label: "-3.5", color: "#a0a8b4" },
    { label: "-3", color: "#9da5b4" },
    { label: "-2.5", color: "#9aa2b4" },
    { label: "-2", color: "#97a0b4" },
    { label: "-1.5", color: "#949db4" },
    { label: "-1", color: "#919ab4" },
    { label: "-0.5", color: "#949bb5" },
    { label: "0", color: "#9098b4" },
    { label: "0.5", color: "#8c95b3" },
    { label: "1", color: "#8892b2" },
    { label: "1.5", color: "#808cb0" },
    { label: "2", color: "#7c89af" },
    { label: "2.5", color: "#7886ae" },
    { label: "3", color: "#7483ac" },
    { label: "3.5", color: "#7080ab" },
    { label: "4", color: "#6c7daa" },
    { label: "4.5", color: "#6779a9" },
    { label: "5", color: "#6376a8" },
    { label: "5.5", color: "#5f73a7" },
    { label: "6", color: "#5b70a6" },
    { label: "6.5", color: "#576da4" },
    { label: "7", color: "#4f67a2" },
    { label: "7.5", color: "#4b64a1" },
    { label: "8", color: "#4761a0" },
    { label: "8.5", color: "#435e9f" },
    { label: "9", color: "#415b9e" },
    { label: "9.5", color: "#4361a2" },
    { label: "10", color: "#4568a6" },
    { label: "10.5", color: "#486faa" },
    { label: "11", color: "#4a76ae" },
    { label: "11.5", color: "#4d7db2" },
    { label: "12", color: "#4f84b6" },
    { label: "12.5", color: "#518bbb" },
    { label: "13", color: "#5699c3" },
    { label: "13.5", color: "#599fc7" },
    { label: "14", color: "#5ba6cb" },
    { label: "14.5", color: "#5eadcf" },
    { label: "15", color: "#60b4d4" },
    { label: "15.5", color: "#62bbd8" },
    { label: "16", color: "#65c2dc" },
    { label: "16.5", color: "#67c9e0" },
    { label: "17", color: "#6ad0e4" },
    { label: "17.5", color: "#6fd6e8" },
    { label: "18", color: "#68d6d7" },
    { label: "18.5", color: "#59d6b3" },
    { label: "19", color: "#52d6a2" },
    { label: "19.5", color: "#4bd690" },
    { label: "20", color: "#43d67e" },
    { label: "20.5", color: "#3cd66d" },
    { label: "21", color: "#35d65b" },
    { label: "21.5", color: "#11d518" },
    { label: "22", color: "#11d117" },
    { label: "22.5", color: "#10cd17" },
    { label: "23", color: "#10c816" },
    { label: "23.5", color: "#10c416" },
    { label: "24", color: "#0fbc15" },
    { label: "24.5", color: "#0fb714" },
    { label: "25", color: "#0eb314" },
    { label: "25.5", color: "#0eaf13" },
    { label: "26", color: "#0eab13" },
    { label: "26.5", color: "#0da612" },
    { label: "27", color: "#0da212" },
    { label: "27.5", color: "#0d9e11" },
    { label: "28", color: "#0c9911" },
    { label: "28.5", color: "#0c9510" },
    { label: "29", color: "#0c9110" },
    { label: "29.5", color: "#0b880f" },
    { label: "30", color: "#0b840e" },
    { label: "30.5", color: "#0a800e" },
    { label: "31", color: "#0a7c0d" },
    { label: "31.5", color: "#0a770d" },
    { label: "32", color: "#09730c" },
    { label: "32.5", color: "#096f0c" },
    { label: "33", color: "#096b0b" },
    { label: "33.5", color: "#08660b" },
    { label: "34", color: "#08620a" },
    { label: "34.5", color: "#095e09" },
    { label: "35", color: "#327308" },
    { label: "35.5", color: "#467d08" },
    { label: "36", color: "#5b8807" },
    { label: "36.5", color: "#6f9207" },
    { label: "37", color: "#849d06" },
    { label: "37.5", color: "#98a806" },
    { label: "38", color: "#adb205" },
    { label: "38.5", color: "#c1bd05" },
    { label: "39", color: "#d6c704" },
    { label: "39.5", color: "#ead204" },
    { label: "40", color: "#ffe200" },
    { label: "40.5", color: "#ffd800" },
    { label: "41", color: "#ffd300" },
    { label: "41.5", color: "#ffce00" },
    { label: "42", color: "#ffc900" },
    { label: "42.5", color: "#ffc400" },
    { label: "43", color: "#ffc000" },
    { label: "43.5", color: "#ffbb00" },
    { label: "44", color: "#ffb600" },
    { label: "44.5", color: "#ffb100" },
    { label: "45", color: "#ffac00" },
    { label: "45.5", color: "#ffa700" },
    { label: "46", color: "#ffa200" },
    { label: "46.5", color: "#ff9900" },
    { label: "47", color: "#ff9400" },
    { label: "47.5", color: "#ff8f00" },
    { label: "48", color: "#ff8a00" },
    { label: "48.5", color: "#ff8500" },
    { label: "49", color: "#ff8000" },
    { label: "49.5", color: "#ff0000" },
    { label: "50", color: "#f80000" },
    { label: "50.5", color: "#f10000" },
    { label: "51", color: "#ea0000" },
    { label: "51.5", color: "#e30000" },
    { label: "52", color: "#d50000" },
    { label: "52.5", color: "#cd0000" },
    { label: "53", color: "#c60000" },
    { label: "53.5", color: "#bf0000" },
    { label: "54", color: "#b80000" },
    { label: "54.5", color: "#b10000" },
    { label: "55", color: "#aa0000" },
    { label: "55.5", color: "#a30000" },
    { label: "56", color: "#9b0000" },
    { label: "56.5", color: "#940000" },
    { label: "57", color: "#8d0000" },
    { label: "57.5", color: "#7f0000" },
    { label: "58", color: "#780000" },
    { label: "58.5", color: "#710000" },
    { label: "59", color: "#ffffff" },
    { label: "59.5", color: "#fff5ff" },
    { label: "60", color: "#ffeaff" },
    { label: "60.5", color: "#ffdfff" },
    { label: "61", color: "#ffd4ff" },
    { label: "61.5", color: "#ffc9ff" },
    { label: "62", color: "#ffbeff" },
    { label: "62.5", color: "#ffb3ff" },
    { label: "63", color: "#ff9dff" },
    { label: "63.5", color: "#ff92ff" },
    { label: "64", color: "#ff75ff" },
    { label: "64.5", color: "#fc6bfd" },
    { label: "65", color: "#f960fa" },
    { label: "65.5", color: "#f656f7" },
    { label: "66", color: "#f34bf4" },
    { label: "66.5", color: "#f040f1" },
    { label: "67", color: "#ed36ef" },
    { label: "67.5", color: "#ea2bec" },
    { label: "68", color: "#e720e9" },
    { label: "68.5", color: "#e10be3" },
    { label: "69", color: "#b200ff" },
    { label: "69.5", color: "#ac00fc" },
    { label: "70", color: "#a400f7" },
    { label: "70.5", color: "#9b00f4" },
    { label: "71", color: "#9300ef" },
    { label: "71.5", color: "#8800ea" },
    { label: "72", color: "#8300e8" },
    { label: "72.5", color: "#7900e2" },
    { label: "73", color: "#7200dd" },
    { label: "73.5", color: "#6900db" },
    { label: "74", color: "#05ecf0" },
    { label: "74.5", color: "#05ebf0" },
    { label: "75", color: "#05eaf0" },
    { label: "75.5", color: "#05dde0" },
    { label: "76", color: "#05dce0" },
    { label: "76.5", color: "#05dbe0" },
    { label: "77", color: "#05cdd0" },
    { label: "77.5", color: "#05ccd0" },
    { label: "78", color: "#04bdc0" },
    { label: "78.5", color: "#04bcc0" },
    { label: "79", color: "#04bbc0" },
    { label: "79.5", color: "#04aeb0" },
    { label: "80", color: "#04adb0" },
    { label: "80.5", color: "#049ea0" },
    { label: "81", color: "#049da0" },
    { label: "81.5", color: "#049ca0" },
    { label: "82", color: "#038e90" },
    { label: "82.5", color: "#038d90" },
    { label: "83", color: "#038c90" },
    { label: "83.5", color: "#037e80" },
    { label: "84", color: "#037d80" },
    { label: "84.5", color: "#036f70" },
    { label: "85", color: "#036e70" },
    { label: "85.5", color: "#036d70" },
    { label: "86", color: "#025f60" },
    { label: "86.5", color: "#025e60" },
    { label: "87", color: "#024f50" },
    { label: "87.5", color: "#024e50" },
    { label: "88", color: "#024d50" },
    { label: "88.5", color: "#023f40" },
    { label: "89", color: "#023e40" },
    { label: "89.5", color: "#023d40" },
    { label: "90", color: "#013030" },
    { label: "90.5", color: "#012f30" },
    { label: "91", color: "#012020" },
    { label: "91.5", color: "#011f20" },
    { label: "92", color: "#011e20" },
    { label: "92.5", color: "#3a67b5" },
    { label: "93", color: "#3a66b5" },
    { label: "93.5", color: "#3a65b5" },
    { label: "94", color: "#3a64b5" },
    { label: "94.5", color: "#3a63b5" },
    { label: "95", color: "#3a62b5" },
]

function generate_canvas({
    HEIGHT = 30,
    WIDTH = 250,
    FONT_SIZE = 8,
    FONT_COLOR = "#000",
} = {}) {
    // TODO: DPR Doesn't size appropriately in mobile (is HUGE)
    //          disabling until investigated further

    // var dpr = window.devicePixelRatio || 1
    var dpr = 1
    // console.log(dpr)
    // generate and setup the canvas element
    let canvas = document.createElement("canvas")

    canvas.height = HEIGHT * dpr
    canvas.width = WIDTH * dpr
    canvas.style.height = canvas.height + "px"
    canvas.style.width = canvas.width + "px"
    canvas.style.display = "block"
    canvas.id = "hrrr_legend_canvas"

    // DEBUG STYLING
    //canvas.style.setProperty('border', "1px solid black")
    //canvas.style.setProperty('margin', "1em")

    // get the 2d context of canvas for drawing
    var ctx = canvas.getContext("2d")
    ctx.scale(dpr, dpr)
    ctx.font = `${FONT_SIZE}px sans-serif`

    // define some attributes based on the passed properties
    const padding_left = FONT_SIZE * 0.5
    const padding_right = FONT_SIZE * 2.5
    const horiz_padding = padding_left + padding_right

    //const rampHeight = (HEIGHT * .5) //- FONT_SIZE*2

    const tick_height = FONT_SIZE * 0.5
    const labelYstart = HEIGHT

    const labelXoffset = FONT_SIZE
    const endTicXoffset = 1

    const rampWidth = WIDTH - horiz_padding
    const rampHeight = HEIGHT - FONT_SIZE - tick_height //- FONT_SIZE*2

    /*
    console.log(`
        HEIGHT: ${HEIGHT}
        WIDTH: ${WIDTH}

        padding_left: ${padding_left}
        padding_right: ${padding_right}
        rampWidth: ${rampWidth}
        rampHeight: ${rampHeight}
        
        tick_height: ${tick_height}
        labelYstart: ${labelYstart}
        firstLabelXoffset: ${firstLabelXoffset}
        labelXoffset: ${labelXoffset}
        endTicXoffset: ${endTicXoffset}
    `)
    */

    // Draw HRRR color ramp and labels
    let zeroLen_HRRR_ramp = HRRR_ramp.length - 1
    let gradient = ctx.createLinearGradient(0, 0, rampWidth, 0)

    // reusable method to draw ticks/labels on the gradient
    let draw_tick = (ctx, label, x, tick_offset = 0, text_offset = 0) => {
        // First label and tic
        // NOTE FOR SANITY: TEXT DRAWS UP FROM SPECIFIED Y
        ctx.fillStyle = FONT_COLOR
        ctx.fillText(label, x + text_offset, labelYstart)

        // Draw tic
        ctx.strokeStyle = FONT_COLOR
        ctx.beginPath()
        ctx.moveTo(x + tick_offset, HEIGHT - FONT_SIZE)
        ctx.lineTo(x + tick_offset, HEIGHT - FONT_SIZE - tick_height)
        ctx.stroke()
    }

    for (let i = 0; i < HRRR_ramp.length; i++) {
        // draw the color ramp
        let rampStop = HRRR_ramp[i]
        let stop = i / zeroLen_HRRR_ramp
        gradient.addColorStop(stop, rampStop["color"])

        if (i == 0) {
            // First label and tic
            draw_tick(
                ctx,
                rampStop["label"],
                padding_left + stop * rampWidth,
                endTicXoffset,
                -(FONT_SIZE * 0.5)
            )
        } else if (i % 32 == 0) {
            // Intermediate labels and tic
            draw_tick(
                ctx,
                rampStop["label"],
                padding_left + stop * rampWidth,
                0,
                -labelXoffset
            )
        } else if (i == 255) {
            // Final label and tic
            draw_tick(
                ctx,
                rampStop["label"] + " dBZ",
                padding_left + stop * rampWidth,
                -endTicXoffset,
                -labelXoffset
            )
        }
    }

    ctx.fillStyle = gradient
    //ctx.fillRect(padding_left, 0, rampWidth, rampHeight)

    ctx.fillRect(padding_left, 0, rampWidth, rampHeight)

    //console.log(canvas)

    return canvas
}

// add the generated canvas to a visualization to be used as a legend
let legend_wrapper = document.createElement("div")
let title = document.createElement("h4")
title.innerHTML = "HRRR Rainfall Forecast (dbZ)"

legend_wrapper.append(
    title,
    generate_canvas({
        WIDTH: 280,
        HEIGHT: 30,
        FONT_COLOR: "#fff",
        FONT_SIZE: 10,
    })
)

let Legend_visual = new Visuals.Markup_visual({
    template: legend_wrapper,
})
// console.log(Legend_visual.template)

const REFLECTIONS_PARSER = {
    legend: Legend_visual,
}

export default REFLECTIONS_PARSER
