<template>
    <div class="navbar">
        <div class="nav_item app_title">UL Flood Forecast Portal</div>

        <a class="about_us" @click="open_about">About</a>

        <div class="nav_item app_logo">
            <a href="https://floodcenter.louisiana.edu/" target="_blank">
                <img src="@/assets/LWFC_interlocking_small.thumb.png" />
            </a>
        </div>

        <div class="nav_item app_logo">
            <a href="https://floodcenter.louisiana.edu/" target="_blank">
                <img src="@/assets/EngineeringVertical.thumb.png" />
            </a>
        </div>

        <sweet-modal ref="about_us">
            <h2>About the Flood Forecasting Portal</h2>

            <p class="text_wall">
                UL Flood Forecast Portal is a modular forecast execution and
                data visualization platform developed for the Vermillion River
                in southwest Louisiana (and applicable anywhere). The portal
                allows detailed hydraulic models such as HEC-RAS to be combined
                with hydrologic models or data and used in an operational
                forecast capacity. The forecasting system takes advantage of
                pre-generated hydrologic forecasts produced by national
                forecasting system (the National Water Model), but can also be
                coupled with local watershed-specific hydrologic models (e.g.,
                HEC-HMS). UL Flood Forecast Portal achieves this modularity
                using a service-oriented architecture and machine-to-machine
                application programming interfaces (APIs) to allow efficient and
                cost-effective model execution using a variety of computing
                resources. Using these modern software approaches allows UL
                Flood Forecast Portal to be quickly adapted to new hydraulic and
                hydrologic modeling frameworks and rapidly applied to novel
                model domains.
            </p>
            <p class="text_wall">
                To learn more, please visit our
                <a
                    href="https://floodcenter.louisiana.edu/research/projects#Forecastportal"
                    target="_blank"
                    >website</a
                >.
            </p>
        </sweet-modal>
    </div>
</template>

<script>
export default {
    name: "app_header",
    // components: {}

    methods: {
        open_about() {
            this.$refs.about_us.open()
        },
    },
}
</script>

<style lang="scss" scoped>
$logo_width: 80px;
$logo_width_mobile: 60px;
.text_wall {
    text-align: left;
    padding: 0.5em 0;
}
.navbar {
    display: flex;
    align-content: flex-start;
    // align-items: flex-start;
    flex-direction: row;

    height: 100%;
    width: 100%;
    padding: 0.5em 0.5em;
    font-size: 1.25em;
    background: #f8f9fa;

    // .sweet-content-content{

    // }

    .about_us {
        display: inline-flex;
        align-self: center;
        padding: 0.2em 1em;
        background: #039be5;
        border-radius: 0.5em;
        color: #fff;
        transition: all $transition_speed_quick;
        cursor: pointer;
        &:hover {
            @include shadow;
            background: #12b0ff;
        }
    }

    .nav_item {
        display: inline-flex;

        margin: 0 0.5em;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .app_logo {
        width: $logo_width;
        flex-grow: 0;

        a {
            height: 100%;
            width: 100%;
            // img {
            //     // height: 85%;
            //     // width: 85%;

            // }
        }
    }

    .app_title {
        align-self: center;
        flex-grow: 1;
    }
}

@include phone-only {
    .navbar {
        font-size: 1em;

        .app_logo {
            width: $logo_width_mobile;
            flex-grow: 0;

            a {
                height: 100%;
                width: 100%;
            }
        }
    }
}
</style>
