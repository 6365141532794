import { library } from "@fortawesome/fontawesome-svg-core"

// pull utilized icons here
import {
    faMapMarkedAlt,
    faChartLine,
    faSpinner,
    faExclamationTriangle,
    faTimesCircle,
    faClipboardList,
    faTools,
    faLayerGroup,
    faEye,
    faEyeSlash,
    faExpandArrowsAlt,
    faCompressArrowsAlt,
    faAngleUp,
    faAngleDown,
    faSatellite,
    faRoad,
    faQuestion,
    faSort,
    faClock,
    faHistory,
} from "@fortawesome/free-solid-svg-icons"

// add icons to library
library.add(
    faQuestion,
    faMapMarkedAlt,
    faChartLine,
    faSpinner,
    faExclamationTriangle,
    faTimesCircle,
    faClipboardList,
    faTools,
    faLayerGroup,
    faEye,
    faEyeSlash,
    faExpandArrowsAlt,
    faCompressArrowsAlt,
    faAngleUp,
    faAngleDown,
    faSatellite,
    faRoad,
    faSort,
    faClock,
    faHistory
)
