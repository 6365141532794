<template>
    <div class="legends_container" v-show="this.visible_layers().length > 0">
        <div class="legends_container_title">LEGEND</div>

        <LegendItem
            v-for="layer in visible_layers()"
            :item="layer"
            :key="layer.uid"
        >
        </LegendItem>
    </div>
</template>

<script>
import LegendItem from "./LegendItem"

import { mapGetters, mapActions } from "vuex"
// import { Layer, LayerCollection } from "@/scripts/structures/layer"

export default {
    name: "Legends",
    components: {
        LegendItem,
    },
    computed: {
        ...mapGetters([
            /* forecast states */
            "getLayers",
        ]),
    },

    methods: {
        visible_layers() {
            return this.getLayers.filter(
                (layer) => layer.visible == true && layer.legend != null
            )
        },
    },
}
</script>

<style lang="scss" scoped>
.legends_container {
    transition: all $transition_speed;

    background: $trans_darken;

    color: #fff;
    padding: 1em 0.5em;
    margin: 0.5em 0.5em;
    border-radius: 0.75em;
    // width: 100%;

    .legends_container_title {
        font-weight: 600;
        margin-bottom: 0.5em;
    }
}
</style>
