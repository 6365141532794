import Vue from "vue"
import App from "./App.vue"

import store from "./store"

import "ol/ol.css"

// apexcharts
// import VueApexCharts from 'vue-apexcharts'
// import VueApexCharts from 'vue-apexcharts'
// Vue.component('apexchart', VueApexCharts)

// import the moment/timezone libraries and register the template filter with vue
import VueMoment from "vue-moment"
import moment from "moment-timezone"
Vue.use(VueMoment, {
    moment,
})

// Modal support
import SweetModal from "sweet-modal-vue/src/plugin"
Vue.use(SweetModal)

// toggle button
import ToggleButton from "vue-js-toggle-button"
Vue.use(ToggleButton)

// load the font awesome icon component and map it to a vue tag
// import the fontawesome configuration which updates the library
import "./fontawesome_config"

// import the FA vue component and register it
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
Vue.component("font-awesome", FontAwesomeIcon)

// import the slider component
// import VueSlider from 'vue-slider-component'
// import 'vue-slider-component/theme/default.css'
// Vue.component('slider', VueSlider)

Vue.config.productionTip = false

new Vue({
    store,
    render: (h) => h(App),
}).$mount("#app")
