import { Object_To_URL_PARAMS } from "@/scripts/url_helpers"
import { Layer } from "@/scripts/structures/layer"
import Visuals from "@/scripts/structures/visualizations"

const BASE_URL = "https://mesonet.agron.iastate.edu/cgi-bin/mapserv/mapserv"

// time parameter doesn't appear to have an effect on the layer returned so commenting out for now

// sample legend request
/* 

*/

// NEXRAD potential replacement for MRMS
/* 
https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n1p.cgi?version=1.3.0&request=GetMap&format=image/png&crs=EPSG:4326&bbox=-126.210938,25.641526,-65.566406,48.922499&width=760&height=360&SERVICE=WMS&layers=nexrad-n1p

*/

/* we found the legend graphic:
    https://mesonet.agron.iastate.edu/images/mrms_q3_p1h.png

    // ~/assets/mrms_q3_p1h.png
*/
import mrms_legend_img from "@/assets/mrms_q3_p1h.png"
function generate_legend() {
    // generate the dom object
    let wrapper = document.createElement("div")

    let title = document.createElement("h4")
    title.innerHTML = "MRMS Radar Rainfall (in/hour)"

    let legend = document.createElement("img")
    legend.setAttribute("src", mrms_legend_img)

    wrapper.append(title)
    wrapper.append(legend)

    // add html to a markup visual
    let vis = new Visuals.Markup_visual({
        template: wrapper,
    })

    return vis
}

// function generate_MRMS_request(utc_date, bbox, crs) {
function generate_MRMS_request(bbox, crs) {
    // if(!(utc_date instanceof Date)) return;

    // let utc_date_str = utc_date.toISOString().substr(0,10);
    // let utc_time_str = utc_date.toISOString().substr(11,5)

    // metadata request
    // https://mesonet.agron.iastate.edu/cgi-bin/mapserv/mapserv?VERSION=1.1.1&REQUEST=GetMetadata&map=/opt/iem/data/wms/us/mrms_nn.map&layer=mrms_p1h

    let mrms_params = {
        version: "1.3.0",
        request: "GetMap",
        format: "image/png",
        bbox,
        crs,
        map: "/opt/iem/data/wms/us/mrms_nn.map",

        width: "760",
        height: "360",

        /*  FAIRLY POSITIVE THAT THE DATE/TIME ARE HAVING ABSOLUTELY NO EFFECT ON RETURNED LAYER */
        // date: utc_date_str,
        // time: utc_time_str,

        layers: "mrms_p1h",
    }

    let mrms_request = `${BASE_URL}?${Object_To_URL_PARAMS(mrms_params)}`

    return mrms_request
}

let MRMS_LAYER = function({
    // time,
    bbox,
    projection,
    addtl_layer_config = {},
}) {
    // if(!time) throw Error('MRMS_LAYER: a time parameter is expected for Layer generation!')
    if (!bbox)
        throw Error(
            "MRMS_LAYER: a bbox parameter is expected for Layer generation!"
        )
    if (!projection)
        throw Error(
            "MRMS_LAYER: a projection parameter is expected for Layer generation!"
        )

    let mrms_url = generate_MRMS_request(bbox, projection)

    return new Layer({
        ...addtl_layer_config,

        type: "tile",
        url: mrms_url,
        legend: generate_legend(),
    })
}

export default MRMS_LAYER
