import Visuals from "@/scripts/structures/visualizations"
import moment from "moment"
import _merge from "lodash/merge"

const EXPECTED_DATA = ["datetime", "tz", "stage", "streamflow"]

const SERIES_NUM_HOURS = 12
const DATETIME_FORMAT = "M/D h:mm a"
const SHORT_DATETIME_FORMAT = "M/D - h a"
const SHORT_DATETIME_FORMAT_alt = "M.D ha"
const CHART_COLORS = ["#ff2222", "#04529F"]
const METRIC_CODE_MAPPING = {
  "00065": "gage",
  "00060": "streamflow",
  "00045": "precip"
}
const METRIC_META = {
  //"Elevation above NGVD 1929, ft"
  // "Gage height, ft"
  //

  gage: {
    chart_buffer: 0,
    chart_type: "line",
    chart_height: 260,
    units: "feet",
    unit_abbrv: `ft`,
    display: "Gage Height"
  },

  streamflow: {
    // "Streamflow, ft&#179;/s"
    chart_buffer: 100,
    chart_type: "line",
    chart_height: 190,
    units: "cubic feet/second",
    unit_abbrv: `cfs`,
    display: "Streamflow"
  },

  precip: {
    // "Streamflow, ft&#179;/s"
    chart_buffer: 0,
    interval_scale: 0.1,
    chart_type: "area",
    units: "inches",
    unit_abbrv: "in",
    display: "Precipitation"
  }
}
let GROUP_CHARTS = false

let CHART_OPTIONS_BASE = {
  streamflow: {
    colors: CHART_COLORS,
    chart: {
      id: "streamflow",
      group: GROUP_CHARTS ? "cross_section" : null,
      fontFamily: "Lato, sans-serif",
      // background: "transparent",
      background: "#333",

      toolbar: {
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true
        }
      }
    },

    theme: {
      mode: "dark"
    },

    noData: {
      text: "There doesn't appear to be any data provided for this feature!",
      align: "center",
      verticalAlign: "middle"
    },

    legend: {
      show: true,
      showForSingleSeries: true
    },

    xaxis: {
      tickAmount: SERIES_NUM_HOURS,
      labels: {
        rotateAlways: true,
        formatter: value => {
          return moment(value).format(SHORT_DATETIME_FORMAT)
        }
      }
    },

    yaxis: {
      // min:0,
      // max:5,
      tickAmount: 4,
      labels: {
        // minWidth: 60,
        minWidth: 60,
        maxWidth: 60,

        formatter: value => {
          return value.toFixed(0)
        }
      },
      title: {
        // text: "test y-axis label"
        style: {
          // fontSize: "14px",
          fontSize: "12px",
          fontWeight: 600
        }
      }
    },
    tooltip: {
      x: {
        formatter: value => {
          return moment(value).format(DATETIME_FORMAT)
        }
      }
    }
  },

  gage: {
    colors: CHART_COLORS,
    chart: {
      id: "gage",
      group: GROUP_CHARTS ? "cross_section" : null,
      toolbar: {
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true
        }
      },

      fontFamily: "Lato, sans-serif",
      // background: "transparent",
      background: "#333",
      type: "line"
    },

    theme: {
      mode: "dark"
    },
    stroke: {
      // LINE WIDTHS
      width: [4, 2]
    },

    noData: {
      text: "There doesn't appear to be any data provided for this feature!",
      align: "center",
      verticalAlign: "middle"
    },

    legend: {
      show: true,
      showForSingleSeries: true
    },
    title: {
      align: "center"
    },

    xaxis: {
      tickAmount: SERIES_NUM_HOURS,
      type: "category",
      labels: {
        rotateAlways: true,
        formatter: function(value) {
          return moment(value).format(SHORT_DATETIME_FORMAT) // The formatter function overrides format property
        }
      }
    },

    yaxis: {
      tickAmount: 4,
      labels: {
        minWidth: 60,
        maxWidth: 60,

        align: "left",

        formatter: value => {
          // return value.toFixed(0)
          return parseFloat(value.toFixed(2))
        }
      },
      title: {
        // text: "test y-axis label"
        style: {
          // fontSize: "14px",
          fontSize: "12px",
          fontWeight: 600
        }
      }
    },

    tooltip: {
      x: {
        formatter: value => {
          return moment(value)
            .format(DATETIME_FORMAT)
            .toString()
        }
      }
    }
  }
}

const NWIS_DATA_PARSER = {
  _currentSite: null,
  _sites: {},

  add_site(site_id, site_info) {
    if (!site_id || !site_info) {
      console.warn(
        "NWIS_DATA_PARSER.add_site: site_id (String) and site_info (Object) are required to add site"
      )
      return
    }

    this._sites[site_id] = site_info
  },

  get_metric_key(code) {
    return METRIC_CODE_MAPPING[code]
  },
  get_metric_meta(code) {
    let metric_key = METRIC_CODE_MAPPING[code]
    return METRIC_META[metric_key]
  },

  chart_tooltip_formatter(value, { seriesIndex, w }) {
    if (typeof value == "undefined") return

    let s_metrics = w.config.series.map(s => s._id)
    let val = value.toFixed(2)
    let unit = this.get_metric_meta(s_metrics[seriesIndex]).unit_abbrv

    return `${val} ${unit}`
  },

  loading_visuals() {
    let loading_msg = document.createElement("div")
    loading_msg.innerHTML = "Loading NWIS data..."
    let Info = new Visuals.Markup_visual({
      template: loading_msg
    })

    return [Info]
  },

  generate_visuals(site_id, timeseries) {
    site_id = site_id ?? this._currentSite
    if (!this._sites[site_id]) return null
    if (!timeseries) return null
    // console.log(timeseries)

    let time_range_str = `${moment(timeseries[0]?.values[0]?.dateTime).format(
      SHORT_DATETIME_FORMAT_alt
    )} - ${moment(
      timeseries[0]?.values[timeseries[0]?.values.length - 1]?.dateTime
    ).format(SHORT_DATETIME_FORMAT_alt)}`
    // console.log(time_range_str)

    let site = {
      name: this._sites[site_id]?.meta?.name ?? "Site name not available"
      // data: this._sites[site_id]?.data || [],
      // metrics: this._sites[site_id]?.metrics || [],
    }

    // let timeseries =

    let title_obj = document.createElement("h4")
    title_obj.style["textAlign"] = "center"
    title_obj.innerHTML = site.name || `Site Code: ${site_id}`
    // console.log(title_obj)
    // let title = site.name || `Site Code: ${site_id}`
    let Info = new Visuals.Markup_visual({
      // template: `
      //     <h4 style="text-align:center">${title}</h4>
      // `,
      template: title_obj
    })

    let formatted_series =
      timeseries.length == 0
        ? null
        : (() => {
            // metrics' headers consist of [timseries id]_[metric_code](_cd if subject to revision)
            // let output = `${Object.keys(timeseries[0]).join(', ')}`;

            let series = timeseries.map(metric => {
              return {
                code: metric.code,
                series: {
                  _id: metric.code,
                  name: this.get_metric_meta(metric.code).display,
                  data: metric.values
                    .map(entry => {
                      // dont include 'noData' values
                      if (metric.noDataValue == entry.value) return
                      //   console.log(entry.dateTime)
                      return {
                        x: new Date(entry.dateTime).getTime(),
                        // x: entry.dateTime,
                        y: entry.value
                      }
                    })
                    .filter(entry => typeof entry != "undefined")
                }
              }
            })
            // return output + site.data.map(row => `<div>${Object.values(row).join(', ')}</div>`).join('')
            return series
          })()

    let charts = formatted_series.map(data => {
      let metric_key = this.get_metric_key(data.code)
      let metric_display = this.get_metric_meta(data.code)?.display
      let metric_unit = this.get_metric_meta(data.code)?.unit_abbrv

      let min = Math.floor(Math.min(...data.series.data.map(s => s.y)))
      let max = Math.ceil(Math.max(...data.series.data.map(s => s.y)))
      // let tickAmount = Math.min(max - min, 4)

      let export_name = `${site.name} (NWIS ${metric_display}, ${time_range_str})`

      let merged_config = _merge(CHART_OPTIONS_BASE[metric_key], {
        chart: {
          toolbar: {
            export: {
              svg: {
                filename: export_name
              },
              png: {
                filename: export_name
              },
              csv: {
                filename: export_name
              }
            }
          }
        },
        tooltip: {
          //   x: {
          //     formatter: this.chart_tooltip_x_formatter.bind(this)
          //   },
          y: {
            formatter: this.chart_tooltip_formatter.bind(this)
          }
        },
        xaxis: { type: "category" },
        yaxis: {
          min,
          max,
          // tickAmount,
          title: {
            text: `${metric_display ?? "unknown metric"} (${metric_unit})`
          }
        }
      })

      return new Visuals.Chart_visual({
        // title: `Cross section: ${this.current_cross_section_id()}`,
        // subtitle: `Model Datum: ${this._floodModel.vertical_datum} (${this._floodModel.vertical_unit})`,
        options: merged_config,
        series: [data.series],
        height: this.get_metric_meta(data.code)?.chart_height ?? 190
      })
    })

    // let data_dump = new Visuals.Markup_visual({
    //     template: `
    //         <h4>Data</h4>
    //         ${data_output}
    //     `
    // })

    return [Info, /* metrics_dump ,*/ ...charts /* data_dump */]
  }
}

export default NWIS_DATA_PARSER
