// import 'ol/ol.css';
// import { Map, View } from 'ol';
import Map from "ol/Map"
import View from "ol/View"
import TileLayer from "ol/layer/Tile"
import VectorLayer from "ol/layer/Vector"

// import Select from 'ol/interaction/Select';
import { fromLonLat } from "ol/proj"

import OSM_Source from "ol/source/OSM"
import Vector_Source from "ol/source/Vector"
import Tile_Source from "ol/source/TileWMS"
import XYZ_Source from "ol/source/XYZ"
import Color from "color"

import { GeoJSON, KML } from "ol/format"
// import KML from 'ol/format/KML';
import { buffer } from "ol/extent"

import { transformExtent } from "ol/proj"

// styling
import { Style, Stroke, Fill, Circle, RegularShape } from "ol/style"

// interaction
import {
    Select,
    // DragRotateAndZoom,
    defaults as defaultInteractions,
} from "ol/interaction"

// import interface 'Layer' object
// import Layer from '@/scripts/layer'

/**
 * singleton object for handling map actions
 */
const OL_Utility = {
    props: {
        ZOOM_EXTENT_BUFFER: 2000,
        DATE_FORMAT: "M/D/YY h:mm a",
        DEFAULT_COORDS: ["-98.585522", "39.8333333"],
        DEFAULT_ZOOM: 4,
        DEFAULT_PROJECTION: "EPSG:3857",
        DEFAULT_BBOX: [
            -23497932.822872348,
            233516.64360559173,
            1548952.6056142095,
            9449987.766119003,
        ],
        // DEFAULT_BBOX: [ -23497932.82, 233516.64360, 1548952.6056, 9449987.766 ],
        DEFAULT_LAYER_COLOR: "#0099ff",
        ALT_LAYER_COLORS: [
            "#D47829", // orange
            "#0cdd5A", // light green
            "#A62611", // red
            "#1F6586", // dark blue
            "#42855F", // dark green
        ],

        // map 'interface layer types' to OL recognized constructors
        LAYER_TYPE_MAPPING: {
            geojson: VectorLayer,
            kml: VectorLayer,
            local_geojson: VectorLayer,
            tile: TileLayer,
        },

        LAYER_TYPE_SOURCES: {
            geojson: Vector_Source,
            kml: Vector_Source,
            local_geojson: Vector_Source,
            tile: Tile_Source,
        },
        LAYER_DATA_FORMATS: {
            kml: KML,
        },

        DEFAULT_STYLING_CONFIG: {
            point: {
                point_type: "circle", // "circle" || "triangle" || "square" || "star"

                color: "#0f0",
                opacity: 0.2,
                width: 2,

                fill_color: null, // falls back to 'color' attribute
            },
            line: {
                color: "#0f0",
                opacity: 0.2,
                width: 2,
            },
            polygon: {
                color: "#0f0",
                opacity: 0.2,
                width: 2, // outline width

                pattern_type: "none", // 'none' || 'line' || 'dash' || 'pinstripe'
                pattern_size: 20, // size of the pattern tiles (square)

                // lines drawn on the fill layer (not outline)
                stroke_width: 2,
                stroke_opacity: 0.5,

                fill: true, // transparent color backdrop in polygon
                fill_color: null, // falls back to 'color' attribute
                fill_opacity: null, // falls back to 'opacity' attribute
            },
        },
    },

    // initialization method to be performed in vue components 'mounted' event
    init() {
        this._interactions = {} // map layers to interactions
        this._base_layers = []
        this.registered_layers = []

        this.generate_base_layers()
        this.create_map()
    },

    generate_base_layers() {
        // NOTE: layer's name and the layer key must match for automation
        this._base_layers = [
            {
                name: "base_street",
                ol_layer: new TileLayer({
                    name: "base_street",
                    source: new OSM_Source(),
                }),
            },

            {
                name: "base_sat",
                ol_layer: new TileLayer({
                    name: "base_sat",
                    source: new XYZ_Source({
                        url:
                            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                        maxZoom: 19,
                    }),
                }),
            },
        ]

        // set the first to visible by default
        this._base_layers.forEach((layer, index) =>
            layer.ol_layer.setVisible(index == 0 ? true : false)
        )
    },

    default_base_layers() {
        return this._base_layers
    },

    activate_base_layer(layer_name) {
        // console.log(this._base_layers)
        let selected_layer = this._base_layers.some(
            (layer) => layer.name == layer_name
        )

        if (selected_layer) {
            this._base_layers.forEach((layer) =>
                layer.ol_layer.setVisible(layer_name == layer.name)
            )
        }
    },

    create_map() {
        // remove rotation controls
        var controls = defaultInteractions({ rotate: false })
        var interactions = defaultInteractions({
            altShiftDragRotate: false,
            pinchRotate: false,
        })

        // OpenLayers map
        this.map = new Map({
            target: "map",
            layers: this.default_base_layers().map((layer) => layer.ol_layer),
            view: new View({
                center: fromLonLat(this.props.DEFAULT_COORDS),
                // bbox: this.props.DEFAULT_BBOX,
                zoom: this.props.DEFAULT_ZOOM,
            }),

            controls,
            interactions,
        })
    },

    reset_view() {
        this.map.getView().animate({
            zoom: this.props.DEFAULT_ZOOM,
            center: fromLonLat(this.props.DEFAULT_COORDS),
        })
    },

    current_center() {
        return this.map.getView().getCenter()
    },

    zoom_to_extent(bbox) {
        // Transform WGS84 bbox to EPSG:3857
        let extent = fromLonLat([bbox[0], bbox[1]])
        extent.push(...fromLonLat([bbox[2], bbox[3]]))
        this.map.getView().fit(extent)
    },

    _generate_pattern(pattern_config) {
        // console.log(pattern_config)
        // check if using supported pattern
        let supported_patterns = ["none", "line", "dash", "pinstripe"]

        if (supported_patterns.indexOf(pattern_config.pattern_type) == -1) {
            console.warn(
                `GENERATE_PATTERN: invalid pattern type, '${
                    pattern_config.pattern_type
                }', provided, using default 'none' pattern, support types are: ${supported_patterns.join(
                    ","
                )}`
            )
            pattern_config.pattern_type = "none"
        }

        var canvas = document.createElement("canvas")
        var context = canvas.getContext("2d")

        let parsed_color = Color(pattern_config.color).alpha(
            pattern_config.stroke_opacity
        )
        // create a square canvas
        canvas.width = pattern_config.pattern_size
        canvas.height = pattern_config.pattern_size

        // if fill specified add transparent background
        if (pattern_config.fill) {
            // console.log('in fill')
            let parsed_fill_color = Color(pattern_config.fill_color).alpha(
                pattern_config.fill_opacity
            )
            context.fillStyle = parsed_fill_color
            context.fillRect(
                0,
                0,
                pattern_config.pattern_size,
                pattern_config.pattern_size
            )
        }

        let draw_point = {
            x: 0,
            y: 0,
            place(x, y) {
                this.x = x
                this.y = y
                return this
            },
            /** relative vector translation of current point */
            trans(x, y) {
                this.x += x
                this.y += y
                return this
            },
            /** reset point to 0,0 */
            reset() {
                this.x = 0
                this.y = 0
                return this
            },

            /** get point array */
            get() {
                // console.log(`point = ${this.x}, ${this.y}`)
                return [this.x, this.y]
            },
        }

        // let pattern_size = pattern_config.pattern_size
        // let stroke_width = pattern_config.pattern_size * .5
        switch (pattern_config.pattern_type) {
            case "dash": // slanted dash
                context.beginPath()

                context.strokeStyle = parsed_color.toString()
                context.lineWidth = pattern_config.stroke_width

                //extend past canvas
                context.moveTo(
                    ...draw_point
                        .trans(pattern_config.pattern_size * 0.5, 0)
                        .get()
                ) // mid-top
                context.lineTo(
                    ...draw_point
                        .trans(
                            -pattern_config.pattern_size * 0.5,
                            pattern_config.pattern_size * 0.5
                        )
                        .get()
                ) // left-mid
                context.stroke()
                break

            // case "candycane": // slanted line
            //     context.beginPath();
            //     context.strokeStyle = parsed_color.toString()
            //     context.lineWidth = stroke_width

            //     context.moveTo(...draw_point.place(-stroke_width *.5, stroke_width).get())
            //     context.lineTo(...draw_point.place(stroke_width, -stroke_width*.5).get())

            //     context.moveTo(...draw_point.place(pattern_size + stroke_width*.5, pattern_size - stroke_width).get())
            //     context.lineTo(...draw_point.place(pattern_size - stroke_width, pattern_size + stroke_width * .5).get())
            //     context.stroke()
            //     break;
            case "line": // slanted line
                context.beginPath()
                context.strokeStyle = parsed_color.toString()
                context.lineWidth = pattern_config.stroke_width

                context.moveTo(
                    ...draw_point.trans(pattern_config.pattern_size, 0).get()
                ) // right-top
                context.lineTo(
                    ...draw_point
                        .trans(
                            -pattern_config.pattern_size,
                            pattern_config.pattern_size
                        )
                        .get()
                ) // left-bottom
                context.stroke()
                break

            case "pinstripe": //vertical lines
                context.beginPath()
                context.strokeStyle = parsed_color.toString()
                context.lineWidth = pattern_config.stroke_width

                context.moveTo(
                    ...draw_point
                        .trans(pattern_config.pattern_size * 0.5, 0)
                        .get()
                ) // mid-top
                context.lineTo(
                    ...draw_point.trans(0, pattern_config.pattern_size).get()
                ) // mid-bottom

                //extend past canvas
                // context.moveTo(pattern_config.pattern_size + 1, -1);
                // context.lineTo(-1,pattern_config.pattern_size + 1);
                context.stroke()
                break
        }

        let pattern = context.createPattern(canvas, "repeat")

        return pattern
    },

    generate_polygon_style(pattern_config) {
        let defaults = this.props.DEFAULT_STYLING_CONFIG.polygon
        return new Style({
            fill: new Fill({
                color: this._generate_pattern({
                    ...pattern_config,

                    // add some fallbacks for
                    stroke_width:
                        pattern_config.stroke_width ?? pattern_config.width,
                    stroke_opacity:
                        pattern_config.stroke_opacity ??
                        defaults.stroke_opacity,
                    fill_color:
                        pattern_config.fill_color ??
                        pattern_config.color ??
                        defaults.fill_color ??
                        defaults.color,
                    fill_opacity:
                        pattern_config.fill_opacity ??
                        pattern_config.opacity ??
                        defaults.fill_opacity ??
                        defaults.opacity,
                }),
            }),
            stroke: new Stroke({
                // use passed color
                color: pattern_config.color,
                width: pattern_config.stroke_width ?? 2,
            }),
        })
    },

    generate_style(styling) {
        // convert layer color to useable value (rgb array). if that fails use fallback
        let parsed_color
        let parsed_fill_color
        try {
            // parsed_color = Color(styling.color).rgb().array();
            parsed_color = Color(styling.color)
            if (styling.fill_color) {
                // parsed_fill_color = Color(styling.fill_color).rgb().array();
                parsed_fill_color = Color(styling.fill_color)
            }
        } catch (e) {
            console.warn(
                `OL_MGMT: failed to parse provided layer color '${styling.color}' using fallback, error:`
            )
            console.error(e)
            // parsed_color = Color(this.props.DEFAULT_LAYER_COLOR).rgb().array()
            parsed_color = Color(this.props.DEFAULT_LAYER_COLOR)
        }

        let generated_style
        let point_style

        switch (styling.type) {
            case "point":
                // generate point style based on config
                switch (styling.point_type) {
                    case "star":
                        point_style = new RegularShape({
                            radius: styling.width * 2,
                            radius2: styling.width,
                            points: 5,
                            fill: new Fill({
                                color: styling.fill_color
                                    ? parsed_fill_color
                                          .alpha(styling.opacity)
                                          .toString()
                                    : parsed_color
                                          .alpha(styling.opacity)
                                          .toString(),
                            }),
                            stroke: new Stroke({
                                color: parsed_color.toString(),
                                width: styling.width / 2,
                            }),

                            angle: 0,
                        })
                        break

                    case "triangle":
                        point_style = new RegularShape({
                            radius: styling.width * 2,
                            points: 3,
                            fill: new Fill({
                                color: styling.fill_color
                                    ? parsed_fill_color
                                          .alpha(styling.opacity)
                                          .toString()
                                    : parsed_color
                                          .alpha(styling.opacity)
                                          .toString(),
                            }),
                            stroke: new Stroke({
                                color: parsed_color.toString(),
                                width: styling.width / 2,
                            }),

                            angle: 0,
                        })
                        break
                    case "square":
                        point_style = new RegularShape({
                            radius: styling.width * 2,
                            points: 4,
                            fill: new Fill({
                                color: styling.fill_color
                                    ? parsed_fill_color
                                          .alpha(styling.opacity || 0.5)
                                          .toString()
                                    : parsed_color
                                          .alpha(styling.opacity || 0.5)
                                          .toString(),
                            }),
                            stroke: new Stroke({
                                color: parsed_color.toString(),
                                width: styling.width / 2,
                            }),

                            angle: Math.PI / 4,
                        })
                        break

                    case "circle":
                    default:
                        point_style = new Circle({
                            radius: styling.width * 2,
                            fill: new Fill({
                                color: styling.fill_color
                                    ? parsed_fill_color
                                          .alpha(styling.opacity ?? 0.5)
                                          .toString()
                                    : parsed_color
                                          .alpha(styling.opacity ?? 0.5)
                                          .toString(),
                            }),
                            stroke: new Stroke({
                                color: parsed_color.toString(),
                                width: styling.width / 2,
                            }),
                        })
                        break
                }

                generated_style = new Style({
                    image: point_style,
                })
                break

            case "polygon":
                generated_style = this.generate_polygon_style(styling)
                break

            case "line":
                generated_style = new Style({
                    stroke: new Stroke({
                        color: parsed_color.toString(),
                        width: styling.width,
                    }),
                })
                break

            case "external":
                break // some external layers provide their own styling
            default:
                console.warn(
                    `OL_MANAGEMENT.generate_style: invalid styling type, '${styling.type}', provided for layer! expected 'line','polygon', or 'point'`
                )
                break
        }

        return generated_style
    },

    add_layer(layer, geojson = null) {
        if (this.get_layer_by_id(layer.uid)) {
            throw Error(
                `OL_UTILS: layer with id '${layer.uid}' already exists in registered layers! uid must be unique to add!`
            )
        }

        let layer_index = this.registered_layers.length

        let source_config = {}

        if (layer.type == "kml")
            source_config.format = new KML({ extractStyles: false })
        else source_config.format = new GeoJSON()

        if (layer.type == "local_geojson") {
            if (!geojson)
                console.warn(
                    `OL_UTILS.add_layer: layer,'${layer.name}' with type 'local_geojson', requires a geojson object to be passed! (this layer wont show without it)`
                )

            let projection = this.props.DEFAULT_PROJECTION
            source_config.loader = function(ext, res, proj) {
                // 'this' is in the context of the source now

                this.addFeatures(
                    this.getFormat().readFeatures(geojson, {
                        featureProjection: projection,
                    })
                )
            }
        } else {
            source_config.url = layer.url
            source_config.crossOrigin = "anonymous"
        }

        // get the default styles for the layer type
        let styling_defaults =
            this.props.DEFAULT_STYLING_CONFIG[layer.metadata.type] ?? {}

        let styling_config = {
            ...styling_defaults,

            // add in provided layer metadata
            ...(layer.metadata ?? {}),
        }
        // correct boolean for fill (comes as string and yes this is a mess)
        if (layer.metadata.type == "polygon") {
            switch (styling_config.fill) {
                case "True":
                    styling_config.fill = true
                    break
                case "False":
                    styling_config.fill = false
                    break
                default:
                    styling_config.fill = this.props.DEFAULT_STYLING_CONFIG.polygon.fill
            }
        }

        // console.log(`making layer: ${layer.name}`)
        // console.log(layer.metadata)
        // console.log(styling_config.fill)

        let new_layer = new this.props.LAYER_TYPE_MAPPING[layer.type]({
            visible: layer.visible,
            name: layer.uid,
            // zIndex: new_layer_array.length-index,
            zIndex: this.registered_layers.length,
            style: this.generate_style(styling_config),
            // style: this.generate_layer_style(layer),
            source: new this.props.LAYER_TYPE_SOURCES[layer.type](
                source_config
            ),
        })
        this.registered_layers.push({
            name: layer.uid,
            layer: layer,
            ol_layer: new_layer,
        })

        this.map.addLayer(new_layer)
    },

    get_layer_by_id(layer_id) {
        let selected = this.registered_layers.find(
            (layer) => layer.name == layer_id
        )
        return selected ? selected : null
    },

    remove_layer_by_id(layer_id) {
        let removals = this.registered_layers.filter(
            (layer) => layer.name == layer_id
        )
        removals.forEach((layer) => this.map.removeLayer(layer.ol_layer))
        this.registered_layers = this.registered_layers.filter(
            (layer) => layer.name != layer_id
        )
    },

    set_layer_visibility(layer_id, visibility) {
        let match = this.registered_layers.find(
            (layer) => layer_id == layer.name
        )

        if (match) {
            match.ol_layer.setVisible(visibility)
        }
    },

    add_interaction(interaction, ol_layer) {
        // map the layer to it's associated interactions
        if (ol_layer) {
            let layer_name = ol_layer.get("title")
            if (!this._interactions[layer_name])
                this._interactions[layer_name] = []
            this._interactions[layer_name].push(interaction)
        } else {
            if (!this._interactions["__GLOBAL__"])
                this._interactions["__GLOBAL__"] = []
            this._interactions["__GLOBAL__"].push(interaction)
        }

        this.map.addInteraction(interaction)
    },

    remove_interaction(interaction) {
        this.map.removeInteraction(interaction)
    },

    remove_layer_interactions(layer_id) {
        if (
            layer_id &&
            Object.keys(this._interactions).indexOf(layer_id) != 1
        ) {
            this._interactions[layer_id].forEach((interaction) => {
                this.remove_interaction(interaction)
            })

            delete this._interactions[layer_id]
        }
    },

    // transformed_extent(){
    //     return transformExtent(this.props.DEFAULT_BBOX, this.props.DEFAULT_PROJECTION)
    // }
}

export default OL_Utility
