import { Object_To_URL_PARAMS } from "@/scripts/url_helpers"
import { Layer, LayerCollection } from "@/scripts/structures/layer"
import REFLECTIONS_PARSER from "./reflections_parser"

const BASE_URL = "https://mesonet.agron.iastate.edu/cgi-bin/wms/hrrr/refd.cgi"

// GetLegendGraphic request
/*   
    https://mesonet.agron.iastate.edu/cgi-bin/wms/hrrr/refd.cgi
        ?request=GetLegendGraphic
        &service=WMS
        &version=1.3.0
        &layer=refd_0600
        &format=image/png
        &SLD_VERSION=1.1.0 
*/

/* 
    brian generated a legend canvas for hrrr, source located here

    https://gist.github.com/selimnairb/9bdd404325477ee53c8b38d756d1de3e
*/

function minute_codes_for_hour(utc_hour) {
    if (utc_hour < 0 || utc_hour > 24)
        throw Error(
            "minute_codes_for_hour(): Invalid UTC hour provided to method!"
        )
    let hour = parseInt(utc_hour)

    // need to shift the hour back by 1 to account for IEM offset, i.e. 1am = 0
    hour--
    if (hour < 0) hour = 23

    // console.log(`hour = ${hour}`)
    let minute_codes = []

    for (let i = 0; i < 4; i++)
        minute_codes.push((hour * 60 + 15 * i).toString().padStart(4, "0"))

    return minute_codes
}

function reflection_requests_for_hour(utc_hour, bbox, crs) {
    let request_urls = []
    let minute_codes = minute_codes_for_hour(utc_hour)
    // let bbox = OL_UTILITY.props.DEFAULT_BBOX.join(',')
    // let projection = OL_UTILITY.props.DEFAULT_PROJECTION

    minute_codes.forEach((code) => {
        request_urls.push(
            `${BASE_URL}?${Object_To_URL_PARAMS({
                version: "1.3.0",
                request: "GetMap",
                format: "image/png",
                bbox,
                crs,
                width: "760",
                height: "360",
                dpiMode: "7",

                layers: `refd_${code}`,
            })}`
        )
    })

    return request_urls
}

function generate_reflection_urls_for_hour(utc_date, bbox, crs) {
    if (!(utc_date instanceof Date)) return
    let utc_hour = utc_date.getUTCHours()
    return reflection_requests_for_hour(utc_hour, bbox, crs)
}

let REFLECTION_LAYER = function({
    time,
    bbox,
    projection,
    addtl_layer_config = {},
}) {
    if (!time)
        throw Error(
            "REFLECTION_LAYER: a time parameter is expected for Layer generation!"
        )
    if (!bbox)
        throw Error(
            "REFLECTION_LAYER: a bbox parameter is expected for Layer generation!"
        )
    if (!projection)
        throw Error(
            "REFLECTION_LAYER: a projection parameter is expected for Layer generation!"
        )

    let reflection_urls = generate_reflection_urls_for_hour(
        time,
        bbox,
        projection
    )

    return new Layer({
        ...addtl_layer_config,
        type: "tile",
        url: reflection_urls[0],
        legend: REFLECTIONS_PARSER.legend,
    })
}

export default REFLECTION_LAYER

// kept for reference (will need conversion if going back to collection method)
// LAYERCOLLECTION METHOD, DATA SOURCE IS TO INCONSISTENT TO DEPEND ON MORE THAN ONE
//
// let reflection_collection = new LayerCollection({
//     uid: "ref_layer",
//     name: "Simulated Reflectivity",
//     layer_collection: reflection_urls.map((url, index) => {
//         return new Layer({
//             name:"Simulated Reflectivity",
//             type:"tile",
//             url,
//             // group="",
//             // metadata:{
//             //     ...element.metadata,

//             // },
//             visible: false

//         })
//     })

// })

// reflection_collection.collection.forEach(layer => {
//     OL_UTILITY.add_layer(layer)
// })
