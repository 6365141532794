<template>
    <div id="map_template_wrapper">
        <!-- <h1> map </h1> -->
        <div id="map"></div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { Layer, LayerCollection } from "@/scripts/structures/layer"
import OL_UTILITY from "@/scripts/ol_management"

import Select from "ol/interaction/Select"
import moment from "moment"

import MRMS_LAYER from "@/scripts/addtl_layers/MRMS_layer"
import REFLECTION_LAYER from "@/scripts/addtl_layers/reflections_layer"
import NWIS_LAYER from "@/scripts/addtl_layers/NWIS_layer"

var animation_timer = 500

window.DEBUG_OL_UTIL = OL_UTILITY

let base_layers_mapping = {
    base_street: {
        title: "Street Map",
        display: {
            type: "img",
            path: "map_icons/street_thumbnail.png",
        },
    },

    base_sat: {
        title: "Satellite Map",
        display: {
            type: "img",
            path: "map_icons/satellite_thumbnail.png",
        },
    },
}

export default {
    name: "app_map",

    data() {
        return {
            active_watershed_layer_ids: [],
            active_forecast_layer_ids: [],

            // store the extent in LON/LAT
            model_bbox: null,
        }
    },

    created() {
        window.addEventListener("resize", this.window_resize_evt)
    },

    mounted() {
        // initialize the map
        OL_UTILITY.init()

        let base_layers = OL_UTILITY.default_base_layers()

        let base_layer_reps = base_layers.map((layer) => {
            return {
                name: layer.name,
                title:
                    base_layers_mapping[layer.name]?.title ??
                    "unknown base layer",
                display: base_layers_mapping[layer.name]?.display ?? {
                    type: "icon",
                    glyph: "question",
                },
            }
        })

        this.setBaseLayers(base_layer_reps)
        this.setSelectedBaseLayer(base_layer_reps[0].name)
        // console.log(this.$el)
    },

    computed: {
        ...mapGetters([
            // 'allWatersheds',
            // 'allForecasts',

            // Interface
            "getLayers",
            "getSelectedBaseLayer",

            // 'getSelectedFloodModelFeature',

            // Flood Model
            "getSelectedWatershed",
            "getSelectedForecast",
            "getFeatureSet",
            "getFeatureSet_GeoJSON",
            "getSelectedForecastMode",

            // MRMS (i feel this should be handled better...)
            "getMRMSMeta",

            // NWIS
            "getSelectedNWISSite",
            "getNWISData",
            "NWISSites",
            "loadedNWISSites",
            "getNWISSiteData",
        ]),
    },

    methods: {
        ...mapActions([
            // interface
            "setSelectedLayer",
            "setBaseLayers",
            "setSelectedBaseLayer",
            "addLayers",
            "removeLayers",

            // flood model
            "setCurrentFloodModelFeature",

            // nwis
            "setSelectedNWISSite",
        ]),

        register_selectable_layer(layer_id, callback) {
            let selectable_layer = OL_UTILITY.get_layer_by_id(layer_id)
            let ol_layer = selectable_layer?.ol_layer
            let layer = selectable_layer?.layer

            if (!selectable_layer) {
                console.warn(
                    `Failed to register selectable layer, '${layer_id}'! Layer must already be registered with OL!`
                )
            }

            // generate a custom selection style based on layer metadata
            let selected_style = OL_UTILITY.generate_style({
                // gen1 styling
                type: layer.metadata.type || "polygon",
                point_type: layer.metadata.point_type || "circle",
                color: "#000",
                opacity: 1,
                width: layer.metadata.width * 1.5 || 2,
                fill_color:
                    layer.metadata.color || layer.metadata.fill_color || null,
            })

            // create the interaction which will trigger feature changes based on feature selection on this layer

            let new_select = new Select({
                layers: [ol_layer],
                style: selected_style,
                // style: OL_UTILITY.props.SELECTED_STYLE,
                filter: (feature, layer) => {
                    // check if associated layer has selection enabled in the app
                    // console.log(layer)
                    let matching_layers = this.getLayers.filter(
                        (l) => l.uid == layer.get("name")
                    )

                    if (matching_layers.length) {
                        let selected_layer = matching_layers[0]
                        return selected_layer.selection_enabled
                    }
                },
            })

            // bind the callback method
            new_select.on("select", callback)

            // register the select interaction
            OL_UTILITY.add_interaction(new_select, ol_layer)
        },

        window_resize_evt(e) {
            // console.log("resized map")
            OL_UTILITY.map.updateSize()
        },

        set_layer_visibility(layer_id, visibility) {
            OL_UTILITY.set_layer_visibility(layer_id, visibility)
        },

        resort_layers(id_list) {
            if (!id_list) return

            id_list.forEach((id, index) => {
                let layer = OL_UTILITY.get_layer_by_id(id)
                if (!layer) return

                layer.ol_layer.setZIndex(id_list.length - index)
                // console.log(layer.ol_layer)
            })
        },
    },

    watch: {
        // 'getMRMSMeta': function() {

        // },
        getSelectedWatershed: function() {
            this.removeLayers(this.active_watershed_layer_ids)

            // clear all the watershed layer representaitons
            this.active_watershed_layer_ids.forEach((layer_id) =>
                OL_UTILITY.remove_layer_by_id(layer_id)
            )
            this.active_watershed_layer_ids = []

            if (this.getSelectedWatershed == null) {
                OL_UTILITY.reset_view()
                // OL_UTILITY.remove_base_layer();

                return
            } else {
                // Zoom to model extent
                this.model_bbox = [
                    ...this.getSelectedWatershed.extent_sw.coordinates,
                    ...this.getSelectedWatershed.extent_ne.coordinates,
                ]

                OL_UTILITY.zoom_to_extent(this.model_bbox)

                // generate layer representation objects and setSelectedWatershedLayers
                let layers = []

                // NOTE: getFeatureSet handles base layer

                // for each provided additional layer generate a representation
                this.getSelectedWatershed.model_features.forEach((element) => {
                    let new_layer = new Layer({
                        uid: element.name,
                        name: element.name,
                        type: element.layer_type,
                        url: element.url,
                        group: element.metadata.group || "Watershed",
                        metadata: {
                            ...element.metadata,
                        },
                        visible: element.display_by_default,
                    })
                    layers.push(new_layer)
                })

                // NWIS layer
                let nwis_layer = NWIS_LAYER({
                    // bbox: OL_UTILITY.props.DEFAULT_BBOX,
                    bbox: this.model_bbox,
                    selection_enabled: true,
                    addtl_layer_config: {
                        uid: "nwis",
                        name: `NWIS`,
                        visible: false,
                        group: "USGS Gauges",
                    },
                })
                layers.push(nwis_layer)

                if (this.getSelectedForecastMode == "current") {
                    // GENERATE MRMS LAYER if looking at the current forecasts
                    let mrms_time = this.getMRMSMeta
                        ? `${moment(this.getMRMSMeta.start_valid).format(
                              "ha M/D/YY"
                          )}`
                        : "latest"

                    let mrms_layer = MRMS_LAYER({
                        // time: UTC_forecast_time,
                        bbox: OL_UTILITY.props.DEFAULT_BBOX,
                        projection: OL_UTILITY.props.DEFAULT_PROJECTION,
                        addtl_layer_config: {
                            uid: "mrms",
                            name: `MRMS Radar Rainfall (in/hour), ${mrms_time}`,
                            // type: 'tile',
                            // url: mrms_url,
                            visible: false,
                            group: "Rainfall",
                            metadata: { type: "external" },
                        },
                    })

                    layers.push(mrms_layer)
                }

                // add additional layers to the map itself
                layers.forEach((layer) => {
                    OL_UTILITY.add_layer(layer)
                    this.active_watershed_layer_ids.push(layer.uid)
                })

                this.register_selectable_layer("nwis", (e) => {
                    let selectedFeature = e.target.getFeatures().getArray()[0]
                    if (selectedFeature) {
                        this.setSelectedLayer("nwis")
                        this.setSelectedNWISSite(selectedFeature.getId())
                    } else this.setSelectedNWISSite(null)
                })

                // update the app's layers collection
                // this.addLayers([...layers, nwis_layer, mrms_layer])
                this.addLayers([...layers])
            }
        },

        getSelectedForecast: function() {
            this.removeLayers(this.active_forecast_layer_ids)

            this.active_forecast_layer_ids.forEach((layer_id) =>
                OL_UTILITY.remove_layer_by_id(layer_id)
            )
            this.active_forecast_layer_ids = []

            let reflection_id = "ref_layer"

            // kick out if no selected forecast
            if (this.getSelectedForecast == null) return

            // add reflection layers
            let UTC_forecast_time = new Date(
                this.getSelectedForecast.start_date
            )

            if (this.getSelectedForecastMode == "current") {
                // they only store most recent 24-hours so only show for current current
                let reflection_layer = REFLECTION_LAYER({
                    time: UTC_forecast_time,
                    bbox: OL_UTILITY.props.DEFAULT_BBOX,
                    projection: OL_UTILITY.props.DEFAULT_PROJECTION,
                    addtl_layer_config: {
                        uid: reflection_id,
                        name: "HRRR Rainfall Forecast (dbZ)",

                        visible: false,
                        group: "Rainfall",
                        metadata: { type: "external" },
                    },
                })

                OL_UTILITY.add_layer(reflection_layer)
                this.active_forecast_layer_ids.push(reflection_layer.uid)
                this.addLayers([reflection_layer])
            }
        },

        getSelectedBaseLayer: function() {
            // console.log(`base layer changed to ${this.getSelectedBaseLayer}`)
            OL_UTILITY.activate_base_layer(this.getSelectedBaseLayer)
        },

        // when the feature set changes update the displayed layer on the map
        getFeatureSet: function() {
            if (this.getFeatureSet != null) {
                let base_layer_representaiton = new Layer({
                    uid: "watersheds",
                    name: "Forecast Locations",
                    type: "local_geojson",
                    selectable: true,
                    selection_enabled: true,
                    metadata: {
                        type: "point",
                        color: "#0099ff",
                        opacity: 0.5,
                        width: 3,
                    },
                    // group: "Model features",
                    visible: true,
                })

                this.addLayers(base_layer_representaiton)
                this.active_watershed_layer_ids.push(
                    base_layer_representaiton.uid
                )

                // generate the map layer itself and it's Interactions
                OL_UTILITY.add_layer(
                    base_layer_representaiton,
                    this.getFeatureSet_GeoJSON
                )

                this.register_selectable_layer("watersheds", (e) => {
                    let selectedFeature = e.target.getFeatures().getArray()[0]

                    if (selectedFeature) this.setSelectedLayer("watersheds")
                    this.setCurrentFloodModelFeature(selectedFeature)
                })
            }
        },

        // 'getSelectedNWISSite': function(){

        //     if(!this.getSelectedNWISSite) return
        //     if(!this.getSelectedForecast) return

        // },
    },

    destroyed() {
        window.removeEventListener("resize", this.window_resize_evt)
    },
}
</script>

<style lang="scss" scoped>
#map_template_wrapper {
    height: 100%;
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    background: $background_mid;
}
#map {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    align-items: stretch;

    canvas {
        width: 100%;
    }
}
</style>
