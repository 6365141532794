/**
 * Model to handle retrieval and parsing of MRMS data
 */
import axios from "axios"

const state = {
    latest_mrms_meta: null,
}

const getters = {
    getMRMSMeta: (model) => {
        return model.latest_mrms_meta
    },
}

const actions = {
    async fetchMRMSMeta({ commit }) {
        const mrms_meta = await axios
            .get(
                "https://mesonet.agron.iastate.edu/data/gis/images/4326/mrms/p1h_nn.json"
            )
            .catch((err) => {
                ;`failed to get MRMS metadata: ${err}`
                return null
            })

        commit("updateMRMSMeta", mrms_meta.data.meta)
    },
}

const mutations = {
    updateMRMSMeta: (state, new_meta) => (state.latest_mrms_meta = new_meta),
}

export default {
    state,
    getters,
    actions,
    mutations,
}
