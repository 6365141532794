/**
 * Module serves as a parser for the Flood Model features, though it could stand more refinement
 *  - bump parse_metric functionality into here
 *  - bump annotations min/max calculations into here
 */
import _merge from "lodash/merge"
import Visuals from "@/scripts/structures/visualizations"

const moment = require("moment-timezone")
const DATETIME_FORMAT = "M/D h:mm a"
const DATE_FORMAT = "M/D/YY h a"
const SHORT_DATE_FORMAT = "M/D - h a"
const SHORT_DATETIME_FORMAT_alt = "M.D ha"
const TIME_FORMAT = "h a"
const METRIC_META = {
  stage: {
    chart_buffer: 0,
    interval_scale: 0.25,
    chart_type: "line",
    units: "feet",
    unit_abbrv: "ft",
    display: "Stage"
  },
  precip: {
    chart_buffer: 0,
    interval_scale: 0.1,
    chart_type: "area",
    units: "inches",
    unit_abbrv: "in",
    display: "Precipitation"
  },
  streamflow: {
    // chart_buffer: 100,
    chart_buffer: 0,
    chart_type: "line",
    units: "cubic feet/second",
    unit_abbrv: `ft3/s`,
    display: "Streamflow"
  }
}

const CHART_COLORS = ["#56eeC8", "#04529F"]
let GROUP_CHARTS = false
const EMPTY_DISPLAY_SERIES = {
  // add a dummy yaxis to sync width to other chart
  opposite: true,
  title: {
    text: ".",
    style: {
      fontSize: "14px",
      color: "transparent"
    }
  },
  labels: {
    style: {
      colors: "transparent"
    },
    formatter: value => {
      return "0.0"
    }
  }
}

let CHART_OPTIONS_BASE = {
  streamflow: {
    colors: CHART_COLORS,
    chart: {
      id: "streamflow",
      group: GROUP_CHARTS ? "cross_section" : null,
      fontFamily: "Lato, sans-serif",
      // background: "transparent",
      background: "#333",

      toolbar: {
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true
        }
      }
    },

    theme: {
      mode: "dark"
    },

    noData: {
      text: "There doesn't appear to be any data provided for this feature!",
      align: "center",
      verticalAlign: "middle"
    },

    legend: {
      show: true,
      showForSingleSeries: true
      // height: 10,
    },
    // title: {
    //     text: 'make selections',
    //     align: 'center',
    // },
    xaxis: {
      // type: "datetime",
      // tickPlacement: 'between',
      labels: {
        rotateAlways: true
      }
    },

    yaxis: [
      {
        min: 0,
        max: 5,
        labels: {
          // minWidth: 60,
          minWidth: 60,
          maxWidth: 60,
          formatter: value => {
            return value.toFixed(0)
          }
        },
        title: {
          // text: "test y-axis label"
          style: {
            fontFamily: "Lato, sans-serif",
            fontSize: "12px",
            fontWeight: 600
          }
        }
      },

      EMPTY_DISPLAY_SERIES
    ]
    // tooltip: {
    //   x: {
    //     formatter: value => {
    //       console.log(value)
    //       return moment(value).format(DATETIME_FORMAT)
    //     }
    //   }
    // }
  },

  stage: {
    colors: CHART_COLORS,
    chart: {
      id: "stage",
      group: GROUP_CHARTS ? "cross_section" : null,

      toolbar: {
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true
        }
      },

      fontFamily: "Lato, sans-serif",
      // background: "transparent",
      background: "#333",
      type: "line"
    },

    theme: {
      mode: "dark"
    },
    stroke: {
      // LINE WIDTHS
      width: [4, 2]
    },

    noData: {
      text: "There doesn't appear to be any data provided for this feature!",
      align: "center",
      verticalAlign: "middle"
    },

    legend: {
      show: true,
      showForSingleSeries: true
    },
    title: {
      align: "center"
    },

    xaxis: {
      labels: {
        rotateAlways: true
      }
    },

    yaxis: [
      {
        showAlways: true,
        min: 0,
        max: 5,

        labels: {
          minWidth: 60,
          maxWidth: 60,
          align: "left",
          formatter: value => {
            // console.log(value)
            // return value.toFixed(2)
            return parseFloat(value.toFixed(2))
          }
        },
        title: {
          // text: "test y-axis label"
          style: {
            fontSize: "14px",
            fontWeight: 600
          }
        }
      },
      {
        showAlways: true,
        opposite: true,
        reversed: true,

        min: 0,
        max: 5,

        labels: {
          minWidth: 60,
          maxWidth: 60,
          // minWidth: 60,
          formatter: value => {
            // return value.toFixed(0)

            // return value.toFixed(1)
            return parseFloat(value.toFixed(2))
          }
        },
        title: {
          // text: "test y-axis label"
          style: {
            fontSize: "14px",
            fontWeight: 600
          }
        }
      }
    ]
    // tooltip: {
    //   x: {
    //     formatter: value => {
    //       return moment(value).format(DATETIME_FORMAT)
    //     }
    //   }
    // }
    // tooltip: {
    //     y: {
    //         formatter: chart_tooltip_formatter,
    //     }
    // }
  }
}

function dummy_precip() {
  // let dummy_data = [0,1,2,2,3,1,2,3,3,3,2,1,0,1,3,2,1,3];
  let dummy_data = [
    0.0,
    0.1,
    0.2,
    0.2,
    0.3,
    0.1,
    0.2,
    0.3,
    0.3,
    0.3,
    0.2,
    0.1,
    0.0,
    0.1,
    0.9,
    0.2,
    2,
    0.3
  ]

  let current_metric = {
    // series: [0,1,2,2,3,1,2,3,3,3,2,1,0,1,3,2,1,3],
    series: dummy_data,
    min: Math.min(...dummy_data),
    max: Math.max(...dummy_data)
  }
  let x_title = `dummy precip`
  let y_title = `Precipitation (in)`

  let buffer = 0
  // calculate the min/max values for display on the chart's y axis
  //      values are ceiling/floor of max/min values extended by a buffer
  let display_max = Math.floor(current_metric.max) + 1 + buffer
  let display_min = Math.max(Math.floor(current_metric.min) - buffer, 0)

  let series = {
    _id: "precip",
    type: "area",
    name: "Precipitation (in)",
    data: current_metric.series
  }

  return {
    x_title,
    y_title,
    series,
    display_min,
    display_max
  }
}

const FLOOD_MODEL_DATA_PARSER = {
  /***************************************
   * Utilty Internals
   ***************************************/
  _floodModel: null,
  _forecast: null,
  _crossSection: null, // PROTIP: crossSection is based on the selected map feature

  _digested_crossSection: null,
  _digested_forecasts: null,
  _units: null,

  _annotations: [],
  _use_annotations: false,
  props: {
    REQUIRED_CROSS_SECTION_METRICS: ["flood_stages", "historical_events"],
    FLOOD_STAGE_META: {
      low_flow: {
        name: "Low flow",
        color: "#899A84"
      },
      bankfull_elevation: {
        name: "Bankfull elevation",
        color: "#6A7B65"
      },
      road_elevation: {
        name: "Road elevation",
        color: "#364A3D"
      },

      action_stage: {
        name: "Action flood stage",
        color: "#fffe54" // yellow
      },
      minor_stage: {
        name: "Minor flood stage",
        color: "#f19d38" // orange
      },
      moderate_stage: {
        name: "Moderate flood stage",
        color: "#ea3323" // red
      },
      major_stage: {
        name: "Major flood stage",
        color: "#bc44f6" // purple
      },
      base_flood_elevation: {
        name: "Base flood elevation",
        color: "#8509C3" // deeper purple
      }
    }
  },

  /***************************
   * utilities
   ***************************/

  get_chart_ids() {
    return Object.keys(CHART_OPTIONS_BASE)
  },

  // chart only available if we have a forecast & a crossSection
  chart_available() {
    return this._forecast != null && this._crossSection != null
  },

  set_annotations_use(new_val) {
    this._use_annotations = new_val ?? !this._use_annotations
  },
  // validate feature is valid for the chart
  valid_crossSection(passed_feature) {
    // if cross section is null dont bother checking
    if (!passed_feature) return false

    // verify that all required metrics are
    let has_missing_metrics = this.props.REQUIRED_CROSS_SECTION_METRICS.map(
      key => passed_feature.get(key)
    ).some(metric => typeof metric == "undefined")
    return !has_missing_metrics
  },

  /***************************
   * Exports
   ***************************/
  get_annotations() {
    return this._annotations
  },

  get use_annotations() {
    return this._use_annotations
  },

  /**
   * get the series information for a provided metric key. this method will assume the currently selected forecast/cross_section based on interface, but these values can be passed
   * @param {string} metric the metric to be passed back if available
   * @param {string?} forecast_id (optional) the forecast id to get metric for, defaults to selected interface forecast
   * @param {string?} cross_section_id (optional) the cross section id, defaults to selected map feature
   */
  get_metric(
    metric,
    forecast_id = this.current_forecast_id(),
    cross_section_id = this.current_cross_section_id()
  ) {
    if (forecast_id == null || cross_section_id == null)
      throw Error(
        "Chart_Utils: failed to get metric! a forecast_id & cross_section_id must be specified or currently set in the interface to get metric!"
      )

    let cross_section =
      this._digested_forecasts[forecast_id]?.cross_sections[cross_section_id] ??
      null
    return this.chart_available() && cross_section != null
      ? cross_section[metric]
      : null
  },

  get_metrics(
    forecast_id = this.current_forecast_id(),
    cross_section_id = this.current_cross_section_id()
  ) {
    if (forecast_id == null || cross_section_id == null)
      throw Error(
        "Chart_Utils: failed to get metric! a forecast_id & cross_section_id must be specified or currently set in the interface to get metric!"
      )
    return this.chart_available()
      ? this._digested_forecasts[forecast_id].cross_sections[cross_section_id]
      : null
  },

  get_metric_units(requested_metric) {
    // catch if no forecast selected
    if (!this.current_forecast_id()) {
      console.warn(
        "CHART_UTILS: Attempted to get units with no selected forecast!"
      )
      return null
    }

    let units = this._digested_forecasts[this.current_forecast_id()].meta.units

    if (typeof requested_metric == "undefined") return units

    // catch if requested non expected metric
    if (!units[requested_metric]) {
      console.warn(
        `CHART_UTILS: Attempted to get non-provided metric's, '${requested_metric}', units! Available metrics are: ${Object.keys(
          units
        ).join(", ")}`
      )
      return null
    }

    return units[requested_metric]
  },

  current_forecast_id() {
    return this._forecast ? this._forecast.id : null
  },

  current_start_date() {
    return (
      this._digested_forecasts[this.current_forecast_id()].meta
        .formatted_start_date || null
    )
  },

  current_cross_section_id() {
    return this._digested_crossSection ? this._digested_crossSection.id : null
  },
  current_cross_section_description() {
    return this._digested_crossSection
      ? this._digested_crossSection.description
      : null
  },

  current_labels() {
    if (this._forecast == null) return []
    // return this._digested_forecast[this._forecast.id].meta.datetime
    return this._digested_forecasts[this._forecast.id].labels
  },

  chart_tooltip_formatter(value, { seriesIndex, w }) {
    let s_metrics = w.config.series.map(s => s._id)
    if (typeof value == "undefined") return
    let val = value.toFixed(2)

    let unit = this.get_metric_units(s_metrics[seriesIndex])
    return `${val} ${unit}`
  },
  /***************************
   * Parsing
   *  NOTE: rule of thumb, parsed data into readable state only,
   *          not into a specific api structure (components should map to 3rd party formats)
   ***************************/

  create_annotation(
    text = "empty annotation",
    color = "#f00",
    value1,
    value2 = null
  ) {
    return {
      y: value1,
      y2: value2,
      text,
      color
    }
  },

  generate_annotation_style: function(color) {
    let base_style = {
      strokeDashArray: 15,
      // borderColor: color,
      fillColor: color,
      opacity: 0.5,
      offsetX: 0,
      offsetY: -3,
      yAxisIndex: 0
    }

    let label_style = {
      background: color,
      color: "#fff",
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "Lato",
      cssClass: "chart_annotation",
      padding: {
        left: 5,
        right: 5,
        top: 0,
        bottom: 2
      }
    }

    return {
      base_style,
      label_style
    }
  },

  update_forecast(forecast) {
    this._forecast = forecast
    // this._units = null;

    // only digest if forecast was valid
    if (this._forecast != null) {
      let digested_forecast = {
        meta: null,
        labels: null,
        cross_sections: null
      }

      digested_forecast["meta"] = {
        watershed_model: this._forecast.watershed_model,
        start_date: this._forecast.start_date,
        hour_interval: this._forecast.hour_interval,
        minute_interval: this._forecast.minute_interval,
        // historical_events: this._forecast.historical_events,
        num_intervals: this._forecast.num_intervals,
        units: this._forecast.units
      }

      // this._units = this._forecast.units;

      // Store cross section data for chart series
      digested_forecast["cross_sections"] = {}

      // digest the individual cross sections from the forecast
      // storing the data-series, min/max
      this._forecast.cross_sections.forEach((cs, i) => {
        /* 
                    each cross section id has multiple metrics
                    each metric has a series 
                    each series has a min/max

                    min/max needs to include annotation in calculation
                        (when drawing at least)
                 */
        digested_forecast["cross_sections"][cs.cross_section_id] = {}

        // for each metric store the data series, and the calculated references for its min/max values
        Object.keys(METRIC_META).forEach(metric => {
          if (cs[metric] != null) {
            let series = cs[metric].slice(
              0,
              digested_forecast.meta.num_intervals
            )
            let min = Math.min(...series)
            let max = Math.max(...series)

            digested_forecast["cross_sections"][cs.cross_section_id][metric] = {
              series,
              min,
              max
            }
          }
        })
      })

      // reinitalize labels
      digested_forecast["labels"] = []

      // push starting label
      let currDate = moment(digested_forecast.meta.start_date)
      digested_forecast.labels.push(currDate.format(SHORT_DATE_FORMAT))
      digested_forecast.meta.formatted_start_date = currDate.format(DATE_FORMAT)

      // push new label for specified number of intervals
      for (let i = 1; i < digested_forecast.meta.num_intervals; i++) {
        // TODO: THIS IS TEMPORARY, we are transitioning to only minutes. then we will only keep 'else' portion
        // if (digested_forecast.meta.hour_interval) {
        //   currDate = currDate.add(digested_forecast.meta.hour_interval, "hour")
        // } else {
        //   currDate = currDate.add(
        //     digested_forecast.meta.minute_interval,
        //     "minute"
        //   )
        // }

        // increment by the minute interval
        currDate = currDate.add(
          digested_forecast.meta.minute_interval,
          "minute"
        )

        // only add hour markers
        // if (currDate.minutes() == 0) {
        let nextDate = currDate.format(SHORT_DATE_FORMAT)
        digested_forecast.labels.push(nextDate)
        // } else {
        //   digested_forecast.labels.push("")
        // }
      }

      if (!this._digested_forecasts) this._digested_forecasts = {}
      this._digested_forecasts[forecast.id] = digested_forecast
    }
  },

  update_crossSection(new_crossSection) {
    // clear stored cross section data if passed invalid cross section
    if (!this.valid_crossSection(new_crossSection)) {
      this._crossSection = null
      this._digested_crossSection = null
      this._annotations = []
      return
    }

    this._crossSection = new_crossSection

    if (this._crossSection) {
      this._digested_crossSection = {
        id: this._crossSection.id_,
        description:
          this._crossSection.get("description") ||
          this._crossSection.get("Key_Sections_Description"),
        floodStages: this._crossSection.get("flood_stages"),
        historical_events: this._crossSection.get("historical_events")
      }

      this._annotations = []
      if (this._digested_crossSection.floodStages) {
        let i = 0
        for (let stage in this.props.FLOOD_STAGE_META) {
          if (this._digested_crossSection.floodStages[stage] === null) {
            // Don't annotate undefined stages
            continue
          }
          i += 1
          let meta = this.props.FLOOD_STAGE_META[stage]

          let a = this.create_annotation(
            meta.name,
            meta.color,
            this._digested_crossSection.floodStages[stage]
          )
          // Disable annotations until we have a toggle to turn them on and off
          this._annotations.push(a)
        }
      }

      if (this._digested_crossSection.historical_events) {
        this._digested_crossSection.historical_events.forEach(event => {
          let a = this.create_annotation(
            event.historical_event,
            "#00F",
            event.stage
          )
          this._annotations.push(a)
        })
      }
    }
  },

  parse_metric(metric, min_max_buffer = 1, interval_scale = 1) {
    let current_metric = this.get_metric(metric)
    let units = this.get_metric_units(metric)

    // console.log(this.current_cross_section_description())

    let x_title = `Cross section: ${this.current_cross_section_description()}`
    // let y_title = `${this.METRIC_META[metric].display} (${this.METRIC_META[metric].unit_abbrv})`
    let y_title = `${METRIC_META[metric].display} (${units})`

    // determine if metric buffer/scale are defined, otherwise use passed values
    let buffer =
      typeof METRIC_META[metric].chart_buffer == "undefined"
        ? min_max_buffer
        : METRIC_META[metric].chart_buffer

    let scale =
      typeof METRIC_META[metric].interval_scale == "undefined"
        ? interval_scale
        : METRIC_META[metric].interval_scale

    // calculate the min/max values for display on the chart's y axis
    //      values are ceiling/floor of max/min values extended by a buffer
    // let display_max = Math.ceil(current_metric.max) + buffer;
    let display_min = Math.floor(current_metric?.min || 0) - buffer
    let max_floor = Math.floor(current_metric?.max || 0)

    // set display max to max floor
    let display_max = max_floor
    while (display_max < current_metric?.max ?? display_max) {
      // keep adding scale increments until surpassing actual max
      display_max += scale
    }
    // incorporate additional buffer
    display_max += buffer
    // console.log(current_metric.series)
    let series = {
      _id: metric,

      name: METRIC_META[metric].display || metric,
      type: METRIC_META[metric].chart_type,

      data:
        // current_metric?.series.map(entry => {
        //   console.log(entry)
        //   return {
        //     x: new Date(entry.dateTime).getTime(),
        //     // x: entry.dateTime,
        //     y: entry.value
        //   }
        // })
        current_metric?.series || []
    }

    // console.log(series.data)//series.data = series.data.filter(value => value)
    // handling edge case where all values are the same
    if (display_max == display_min) display_max += interval_scale

    return {
      x_title,
      y_title,
      units,
      series,
      display_min,
      display_max
    }
  },

  generate_visuals() {
    // this.setChartAvailable(false);

    if (!this.chart_available()) return null

    // get max value if enabled otherwise set to 0
    let annotation_max = this._use_annotations
      ? Math.max(...this.get_annotations().map(a => Math.max(a.y, a.y2))) +
        METRIC_META.stage.chart_buffer
      : -999999999
    let annotation_min = this._use_annotations
      ? Math.min(...this.get_annotations().map(a => Math.max(a.y, a.y2))) +
        METRIC_META.stage.chart_buffer
      : 99999999999

    let parsed_stream = this.parse_metric("streamflow")
    let parsed_stage = this.parse_metric("stage")
    let parsed_precip = this.parse_metric("precip")
    // let parsed_precip = dummy_precip();
    // console.log(this._digested_forecasts[this.current_forecast_id()].meta)
    let timerange_str = `${moment(
      this._digested_forecasts[this.current_forecast_id()].meta.start_date
    ).format(SHORT_DATETIME_FORMAT_alt)}`

    // calculate the min/max values for display on the chart's y axis
    //      values are ceiling/floor of max/min values extended by a buffer
    let display_max = Math.ceil(
      Math.max(parsed_stage.display_max, annotation_max)
    )
    let display_min = Math.floor(
      Math.min(parsed_stage.display_min, annotation_min)
    )

    /**
     * Merge in the newly updated chart configuration values for the newly added series
     */

    let stream_chart_options = _merge(CHART_OPTIONS_BASE.streamflow, {
      // title:{
      //     text: chart_title,
      // },
      chart: {
        toolbar: {
          export: {
            svg: {
              filename: `${this.current_cross_section_description()}, (Streamflow, ${timerange_str})`
            },
            png: {
              filename: `${this.current_cross_section_description()}, (Streamflow, ${timerange_str})`
            },
            csv: {
              filename: `${this.current_cross_section_description()}, (Streamflow, ${timerange_str})`
            }
          }
        }
      },

      tooltip: {
        y: {
          formatter: this.chart_tooltip_formatter.bind(this)
        }
      },

      xaxis: {
        // alternative definition depending on chart type
        categories: this.current_labels()
      },

      yaxis: [
        {
          min: parsed_stream.display_min,
          max: parsed_stream.display_max,
          tickAmount: Math.min(
            4,
            parsed_stream.display_max - parsed_stream.display_min
          ), // have a single tick for each 'foot' in range
          // tickAmount: 4,
          title: {
            text: parsed_stream.y_title
          }
        }
      ]
    })

    let stage_chart_options = _merge(CHART_OPTIONS_BASE.stage, {
      chart: {
        toolbar: {
          export: {
            svg: {
              filename: `${this.current_cross_section_description()}, (Stage/Precip, ${timerange_str})`
            },
            png: {
              filename: `${this.current_cross_section_description()}, (Stage/Precip, ${timerange_str})`
            },
            csv: {
              filename: `${this.current_cross_section_description()}, (Stage/Precip, ${timerange_str})`
            }
          }
        }
      },
      tooltip: {
        y: {
          formatter: this.chart_tooltip_formatter.bind(this)
        }
      },

      xaxis: {
        // alternative definition depending on chart type
        categories: this.current_labels()
      },

      yaxis: [
        {
          // STAGE SERIES CONFIG

          min: display_min,
          max: display_max,
          // tickAmount: Math.min(5, display_max - display_min), // have a single tick for each 'foot' in range
          tickAmount: 4,
          title: {
            text: parsed_stage.y_title
          }
        },
        {
          // PRECIP SERIES CONFIG

          min: parsed_precip.display_min,
          max: parsed_precip.display_max,

          // tickAmount: Math.min(5, parsed_precip.display_max - parsed_precip.display_min), // have a single tick for each 'foot' in range
          tickAmount: 2,
          title: {
            text: parsed_precip.y_title
          }
        }
      ]
    })

    // handle annotations separately from merge as the arrays were fighting in the config (ghost values... ooooo spooky)
    stage_chart_options.annotations = {
      chart: {
        id: `Stage/Precip, ${this.current_cross_section_description()}`
      },
      yaxis: this._use_annotations
        ? this.get_annotations().map(a => {
            let styling = this.generate_annotation_style(a.color)
            return {
              ...styling.base_style,
              y: a.y,
              // y2: a.y2 || display_max,
              label: {
                text: `${a.text}: ${a.y} ft`,
                style: styling.label_style
              }
            }
          })
        : []
    }

    let title = this.current_cross_section_description()
      ? this.current_cross_section_description()
      : `Cross section: ${this.current_cross_section_id()}`

    let CHART1 = new Visuals.Chart_visual({
      title,
      subtitle: `Model Datum: ${this._floodModel.vertical_datum} (${this._floodModel.vertical_unit})`,
      options: stream_chart_options,
      series: [parsed_stream.series],
      height: 190
    })

    // let annotations_tool = new Visuals.Chart_tool({
    //     title: "Toggle Annotations",
    //     icon: "clipboard-list",
    //     callback: () => this.set_annotations_use() // this doesn't trigger redraw
    // })

    let CHART2 = new Visuals.Chart_visual({
      options: stage_chart_options,
      // tools: [annotations_tool],
      series: [parsed_stage.series, parsed_precip.series],
      height: 260
    })

    return [CHART1, CHART2]
  }
}

export default FLOOD_MODEL_DATA_PARSER
