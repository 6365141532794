<template>
    <div id="map_layer_list_wrapper">
        <div class="base_layer_selection_wrapper">
            <span class="base_layer_selection_label">Map Background</span>
            <div class="base_layers">
                <div
                    v-for="layer in getBaseLayers"
                    :key="layer.name"
                    :class="[
                        'base_layer',
                        { selected: getSelectedBaseLayer == layer.name },
                    ]"
                    :data-layer-name="layer.name"
                    :title="layer.title"
                    @click="update_base_layer_evt(layer.name)"
                >
                    <template>
                        <img
                            v-if="layer.display && layer.display.type == 'img'"
                            class="image"
                            :src="getImgUrl(layer.display.path)"
                        />

                        <font-awesome
                            v-if="layer.display && layer.display.type == 'icon'"
                            class="icon"
                            :icon="layer.display.icon"
                        />
                    </template>
                </div>
            </div>
        </div>
        <!-- 
          NEED TO FACTOR IN GROUPED/CATEGORIZED LAYERS 
          AND MULTI-Layers (reflection layers)
        -->
        <div class="map_layer_list_container">
            <draggable
                v-bind="dragOptions"
                group="layer_groups"
                @start="drag_start_evt"
                @end="drag_end_evt"
            >
                <transition-group
                    type="transition"
                    :name="!dragging ? 'flip-list' : null"
                >
                    <template v-for="group in this.layer_groups">
                        <div
                            :key="group"
                            :class="[
                                'group_wrapper',
                                { hidden: group_hidden(group) },
                            ]"
                            :data-group="group"
                        >
                            <div
                                v-if="group"
                                @click="toggleGroup"
                                class="group_header handle"
                            >
                                {{ group }}
                                <font-awesome
                                    class="icon"
                                    :icon="
                                        group_hidden(group)
                                            ? 'angle-down'
                                            : 'angle-up'
                                    "
                                />
                            </div>
                            <transition name="fade">
                                <div class="group_items" :data-group="group">
                                    <!-- treat 'undefined' group as parent sortable, but restrict grouped to self sorting -->
                                    <draggable
                                        v-bind="dragOptions"
                                        :group="
                                            group
                                                ? `${group}_layer`
                                                : 'layer_groups'
                                        "
                                        @start="drag_start_evt"
                                        @end="drag_end_evt"
                                    >
                                        <transition-group
                                            type="transition"
                                            :name="
                                                !dragging ? 'flip-list' : null
                                            "
                                        >
                                            <template
                                                v-for="layer in layers_in_group(
                                                    group
                                                )"
                                            >
                                                <ListItem
                                                    v-if="isLayer(layer)"
                                                    @layer-toggled="
                                                        childToggledEvent
                                                    "
                                                    :key="layer.uid"
                                                    :item="layer"
                                                >
                                                    {{ layer.name }}
                                                </ListItem>
                                            </template>
                                        </transition-group>
                                    </draggable>
                                </div>
                            </transition>
                        </div>
                    </template>
                </transition-group>
            </draggable>
        </div>

        <!--         
      <template v-for="(layer) in this.getLayers">


        <ListItem v-if="isLayer(layer)" @layer-toggled="childToggledEvent"  
            :key="layer.uid"             
            :item="layer"
            >
          {{ layer.name }}
        </ListItem>

        
         <ListMultiItem v-if="isCollection(layer)" @layer-toggled="childToggledEvent"  
            :item="layer"
            :key="layer.uid"
            :interval="15"
            :label_formatter="(v) => `value is ${v}`">
            {{layer.name}}
        </ListMultiItem> 

        

        
      </template>       -->
    </div>
</template>

<script>
import ListItem from "./MapLayersListItem.vue"
// import ListMultiItem from './MapLayersListMultiItem.vue';
import { mapGetters, mapActions } from "vuex"
import { Layer, LayerCollection } from "@/scripts/structures/layer"

import draggable from "vuedraggable"

export default {
    name: "MapLayersList",
    components: {
        ListItem,
        draggable,
        // ListMultiItem,
    },
    data() {
        return {
            hidden_groups: [],
            dragging: false,
        }
    },

    computed: {
        ...mapGetters([
            /* forecast states */
            "getSelectedWatershed",
            "getBaseLayers",
            "getSelectedBaseLayer",

            "getLayers",
            // 'getSelectedWatershedLayers',
        ]),

        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "drag_ghost",
                handle: ".handle",
            }
        },

        layer_groups() {
            let layer_groups = [
                ...new Set(
                    this.getLayers.map((item) => item.group.toUpperCase())
                ),
            ]
            layer_groups.sort()
            return layer_groups
        },
    },

    methods: {
        ...mapActions(["setSelectedBaseLayer"]),

        childToggledEvent(payload) {
            this.$emit("layer-toggled", payload)
        },

        isLayer(obj) {
            return obj instanceof Layer
        },
        isCollection(obj) {
            return obj instanceof LayerCollection
        },
        layers_in_group(group) {
            return this.getLayers.filter(
                (layer) => layer.group.toUpperCase() == group
            )
        },

        getImgUrl(pic) {
            return require("@/assets/" + pic)
        },

        toggleGroup(evt) {
            // add class to parent group wrapper
            // evt.target.closest('.group_wrapper').classList.toggle('hidden')

            // change the icon based on hidden
            // let is_hidden = evt.target.closest('.group_wrapper').classList.contains('hidden');
            // evt.target.querySelector('.icon').setAttribute('icon', is_hidden? 'angle-down': 'angle-up')

            let toggled = evt.target
                .closest(".group_wrapper")
                .getAttribute("data-group")
            let group_index = this.hidden_groups.indexOf(toggled)

            if (group_index == -1) this.hidden_groups.push(toggled)
            else this.hidden_groups.splice(group_index, 1)
        },

        group_hidden(group) {
            return this.hidden_groups.indexOf(group) != -1
        },

        update_base_layer_evt(base_layer_id) {
            // this.$emit('base-layer-selected', evt)

            // this.setSelectedBaseLayer(evt.target.getAttribute('data-layer-name'))
            this.setSelectedBaseLayer(base_layer_id)
        },

        // return array of layer_ids in current display order
        current_order() {
            let layer_order = []
            document
                .getElementById("map_layer_list_wrapper")
                .querySelectorAll(".map_layer_list_item_wrapper")
                .forEach((l) => {
                    layer_order.push(l.getAttribute("data-uid"))
                })

            return layer_order
        },

        drag_start_evt(e) {
            // console.log('drag started')
            this.dragging = true
            // console.log(e)
        },
        drag_end_evt(e) {
            // console.log('drag end')
            this.dragging = false
            // console.log(e)

            // trigger layer reindexing in map
            // console.log(current_order)
            this.$emit("update:layer_order", this.current_order())
        },
    },

    watch: {
        getLayers: function() {
            this.$emit("update:layer_order", this.current_order())
        },
    },
}
</script>

<style lang="scss" scoped>
$list_container_width: $sidebar_width;
$list_container_min_width: $sidebar_min_width;

#map_layer_list_wrapper {
    // width: min-content;
    // min-width:400px;

    // width: 100%;

    display: flex;
    flex-direction: column;
    background: $background_dark;
    // fixes horiz-scroll when vertical scroll added
    padding-right: 0;

    // .flip-list-move {
    // transition: transformY 0.5s;
    // }

    .handle {
        position: relative;
        cursor: pointer;
        &:hover {
            // background: red;
            transition: all 0.2s;
            &::after {
                content: "\2195";
                position: absolute;

                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: 1em;
                width: 2em;
                height: 100%;
                right: 0;
                top: 0;
                font-size: 1.2em;
                text-align: center;
                vertical-align: middle;
                background: $trans_darken;
            }
        }
    }

    .no-move {
        transition: transform 0s;
    }
    .drag_ghost {
        opacity: 0.5;
        background: #000;
    }

    .base_layer_selection_wrapper {
        background: rgba(255, 255, 255, 0.2);

        padding: 0 0.5em;
        margin-bottom: 0.25em;
        display: flex;
        flex-direction: row;
        width: 100%;
        // min-width: 400px;       // THIS SERVES AS THE STANDARD WIDTH OF THE WHOLE LISTING CONTAINER

        .base_layer_selection_label {
            display: inline-block;
            align-self: center;
            flex: 1;
        }
        .base_layers {
            display: inline-block;

            .base_layer {
                display: inline-block;
                padding: 0.2em;
                margin: 0.2em;
                border-radius: 0.5em;
                background: $trans_lighten;

                vertical-align: middle;
                text-align: right;
                cursor: pointer;

                transition: all $transition_speed_quick;
                opacity: 0.5;

                &:hover {
                    background: #fff;
                    opacity: 1;
                }
                &.selected {
                    background: #2791bb;
                    opacity: 1;
                }

                .image {
                    height: 30px;
                    border-radius: 0.5em;
                    vertical-align: middle;
                }
                // .icon{}
            }
        }
    }

    .map_layer_list_container {
        // width: 400px
        padding: 0.5em;
    }

    .group_wrapper {
        // display:flex;
        // flex-direction: column;
        // flex: 1 0 auto;
        // min-width: 400px;
        // transition: transform .5s;

        .group_header {
            padding: 0.5em;
            border-radius: 4px;
            font-family: $header_font;
            cursor: pointer;

            .icon {
                float: right;
            }

            &:hover {
                background: $trans_lighten;
            }
        }

        .group_items {
            display: flex;
            flex-direction: column;
        }

        &.hidden {
            /* collapse group when hidden */
            .group_items {
                display: none;
            }
        }
    }
}

/************************************
    DESKTOP STYLING 
************************************/

@include desktop-only {
    #map_layer_list_wrapper {
        // width: $list_container_width;

        .base_layer_selection_wrapper {
            width: 100%;
        }
    }
    // .group_wrapper{
    //         // display:flex;
    //         // flex-direction: column;
    //         // flex: 1 0 auto;
    //         // width: 400px;

    // }
}
</style>
