<template>
    <div
        id="feature_info_wrapper"
        v-bind:class="{
            empty: this.is_empty,
        }"
    >
        <transition name="feature_info_container">
            <div class="feature_info_container" v-if="!this.is_empty">
                <div class="feature_info_container_inner">
                    <div v-if="this.TITLE" class="title">{{ this.TITLE }}</div>

                    <div ref="content" class="feature_info_content">
                        <template v-for="(visual, index) in this.visuals">
                            <div
                                :key="visual.uid"
                                v-if="isChart(visual)"
                                class="feature_info_content_block chart"
                            >
                                <Chart
                                    :title="visual.title"
                                    :subtitle="visual.subtitle"
                                    :height="visual.height"
                                    :options="visual.options"
                                    :series="visual.series"
                                    :tools="visual.tools"
                                ></Chart>
                            </div>

                            <div
                                :key="index"
                                v-if="isMarkup(visual)"
                                class="feature_info_content_block markup"
                                :data-visual-uid="visual.uid"
                            ></div>
                        </template>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import _merge from "lodash/merge" // use lodash to merge object configs (apex is supposed to do this, but doesn't...)
import moment from "moment"

import Chart from "@/components/chart"
import Visuals from "@/scripts/structures/visualizations"
import FLOOD_MODEL_DATA_PARSER from "@/scripts/addtl_layers/floodModel_parser"
import NWIS_DATA_PARSER from "@/scripts/addtl_layers/NWIS_parser"

export default {
    name: "feature_info",
    components: {
        // apexcharts: VueApexCharts,
        Chart,
    },
    props: {
        visuals: {
            type: Array,
        },
        //[MARKUP, CHART1, CHART2]
    },
    data: function() {
        return {
            TITLE: "",
            // TEST_CHART_PROPS: CHART1,

            // this will be a prop ultimately
            // visuals: [MARKUP, CHART1, CHART2],
        }
    },

    // mounted() {

    // },

    computed: {
        ...mapGetters([
            // interface
            "getSelectedLayer",

            /* flood model */
            "getSelectedWatershed",
            "getSelectedForecast",
            "getSelectedFloodModelFeature",
            "useAnnotations",
            "setAnnotationsUse",

            /* NWIS model */
            "getNWISSiteData",
            "getNWISData",
            "getSiteParamCodes",
            "getSelectedNWISSite",
            "getNWISMetrics",
            "getNWISMetricsAtTime",

            "loadedNWISSites",
            "loadedNWISMetricTimes",
            "NWISSites",
        ]),

        // mark chart container as empty if there are no selected forecast/feature in current state
        is_empty() {
            // return !(this.getSelectedForecast && this.getSelectedFeature)
            return false
        },
    },

    methods: {
        ...mapActions([
            "setAnnotationUse",
            "setVisuals",

            // NWIS
            "fetchInstantValues",
            // 'fetchNWISDailyValues',
            // 'fetchNWISDailyValuesMeta',
            "setSelectedNWISSite",
        ]),

        isChart(visual) {
            return visual instanceof Visuals.Chart_visual
        },
        isMarkup(visual) {
            return visual instanceof Visuals.Markup_visual
        },

        isCurrentLayer(layer_id) {
            return layer_id == this.getSelectedLayer
        },

        updateFloodModelVisuals() {
            if (!this.isCurrentLayer("watersheds")) return

            let generated = FLOOD_MODEL_DATA_PARSER.generate_visuals()

            // TODO: CHANGE THIS FOR SOMETHING MORE ELEGANT
            // not the best way to do this, but i need it to tie in

            let annotations_tool = new Visuals.Chart_tool({
                title: "Toggle Annotations",
                icon: "clipboard-list",
                callback: () => this.setAnnotationUse(!this.useAnnotations),
            })
            // add annotations trigger to the 'stage' chart
            if (generated) generated[1].tools = [annotations_tool]

            this.setVisuals(generated)
        },

        floodModelToolCallback() {
            this.setAnnotationsUse(!FLOOD_MODEL_DATA_PARSER.use_annotations)
        },

        updateNWISVisuals() {
            if (!this.isCurrentLayer("nwis")) return

            if (!this.getSelectedNWISSite || !this.getSelectedForecast) {
                this.setVisuals(null)
                return
            }
            let selected = this.getSelectedNWISSite
            let datetime = this.getSelectedForecast.start_date
            this.setVisuals(NWIS_DATA_PARSER.loading_visuals())

            // check if the timeseries for this forecast has already been pulled
            if (this.loadedNWISMetricTimes(selected).indexOf(datetime) != -1) {
                // this.updateNWISVisuals(selected, this.getNWISMetricsAtTime({site_id: selected, datetime }))
                let generated_visuals = NWIS_DATA_PARSER.generate_visuals(
                    selected,
                    this.getNWISMetricsAtTime({
                        site_id: selected,
                        datetime: datetime,
                    })
                )
                this.setVisuals(generated_visuals)
                return
            }

            // pull the information if not present
            this.fetchInstantValues({
                site_id: selected,
                datetime: this.getSelectedForecast.start_date,
            }).then(() => {
                NWIS_DATA_PARSER.add_site(selected, {
                    meta: this.getNWISSiteData(selected),
                })

                // time has passed so double check still relevent
                if (!this.isCurrentLayer("nwis")) return

                //
                if (selected == this.getSelectedNWISSite) {
                    //this.updateNWISVisuals(selected, this.getNWISMetricsAtTime({site_id: selected, datetime }))
                    let generated_visuals = NWIS_DATA_PARSER.generate_visuals(
                        selected,
                        this.getNWISMetricsAtTime({
                            site_id: selected,
                            datetime: datetime,
                        })
                    )
                    this.setVisuals(generated_visuals)
                }
            })

            /* 
                    DAILY VALUE VERSION OF DATA RETRIEVAL 
                        waiting for validation that IV is accurate before deleting
                        WARNING, this is not formatted for current system, requires re-write if swapped to
                */
            // perform fetch if not
            // this.fetchNWISDailyValuesMeta(selected)
            //     .then((meta) =>{
            //         // console.log(`'${selected}' loaded meta`)
            //         let forecast_date = moment(this.getSelectedForecast.start_date).format("YYYY-MM-DD")

            //         // fetch the daily values
            //         this.fetchNWISDailyValues({
            //             site_id: selected,
            //             date: forecast_date
            //         }).then((res) =>{
            //             // console.log(this.getNWISData(this.getSelectedNWISSite))
            //             // console.log(`'${selected}' loaded DVs`)
            //             NWIS_DATA_PARSER.add_site(selected, {
            //                 meta: this.getNWISSiteData(selected),
            //                 data: this.getNWISData(selected),
            //                 metrics: this.getNWISMetrics(selected)
            //             })

            //             // only update visuals if this is still the selected site (racehorse)
            //             if(selected == this.getSelectedNWISSite) this.updateNWISVisuals(selected)
            //         })
            //     })
        },
    },

    watch: {
        /***********************************************
         * FLOOD MODEL Feature methods
         ***********************************************/
        getSelectedWatershed: function() {
            FLOOD_MODEL_DATA_PARSER._floodModel = this.getSelectedWatershed
        },

        getSelectedForecast: function() {
            FLOOD_MODEL_DATA_PARSER.update_forecast(this.getSelectedForecast)
            // NWIS_DATA_PARSER.update_time(this.getSelectedForecast);

            this.updateFloodModelVisuals()
            this.updateNWISVisuals()
        },

        getSelectedFloodModelFeature: function() {
            FLOOD_MODEL_DATA_PARSER.update_crossSection(
                this.getSelectedFloodModelFeature
            )
            this.updateFloodModelVisuals()
        },

        useAnnotations: function() {
            FLOOD_MODEL_DATA_PARSER.set_annotations_use(this.useAnnotations)
            this.updateFloodModelVisuals()
        },

        /***********************************************
         * NWIS Feature methods
         ***********************************************/
        getSelectedNWISSite: function() {
            this.updateNWISVisuals()
        },

        visuals: function(visuals) {
            if (visuals?.length) {
                // this feels hacky but this happens before mounting
                this.$nextTick(() => {
                    let markups = visuals.filter((vis) => this.isMarkup(vis))
                    // console.log(markups)

                    markups.forEach((vis) => {
                        // console.log(`getting markup: ${vis.uid}`)
                        let matching_el = this.$el.querySelector(
                            `.markup[data-visual-uid="${vis.uid}"]`
                        )

                        if (matching_el) {
                            // console.log("found")
                            matching_el.innerHTML = ""
                            matching_el.append(vis.template)
                        }
                    })
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
// Chart Handle
$handle_size: 50px;

$handle_icon_default: #fff;
$handle_icon_disabled: #ccc;
$handle_icon_active: $background_dark;

$handle_back_default: $background_dark;
$handle_back_disabled: #777;
$handle_back_active: red;

// toolbox
$toolbox_height: 46px;
$tool_icon_size: 1.25em;
$tool_icon_padding: 0.25em;
$tool_icon_default: #cfcfcf;
$tool_icon_active: $background_dark;
$tool_icon_hover: $background_dark;

$tool_wrap_default: $background_dark;
$tool_wrap_active: #cfcfcf;
$tool_wrap_hover: #4ab6af;

// $chart_refresh_flash_color: #aaa;

// $chart_size_horiz: 40vw;
// $chart_size_vert: 45vh;
// $chart_size_vert: 570px;

/***********************************
    SHARED STYLING
 ***********************************/

#feature_info_wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;

    // height: 100%;
    // background: $background_dark;
    color: #fff;
    // hide scroll on slide out
    overflow: hidden;

    transition: all $transition_speed;

    .feature_info_container {
        position: relative;
        // margin: auto;
        // background: $background_dark;

        // padding-bottom: $toolbox_height; // for toolbox reserved space

        // overflow: hidden auto;

        transition: all $transition_speed ease;

        .feature_info_container_inner {
            // padding: 1em;
            overflow: hidden auto;

            .title {
                font-family: $header_font;
                font-weight: 600;
                text-align: center;
                // padding: 1em;
            }

            .feature_info_content {
                background: $background_dark;

                .feature_info_content_block {
                    &:first-child {
                        padding-top: 1em;
                    }

                    &.chart {
                        // background: red
                    }
                    &.markup {
                        padding: 1em;
                    }
                }
            }
        }
    }
}

/***********************************
    MOBILE ONLY STYLING
 ***********************************/
@include phone-only {
    #feature_info_wrapper {
        .feature_info_container {
            // height: 100%;
            max-height: 100%;
            // max-height: calc(100% - #{$handle_size});
            // height: $chart_size_vert;

            // padding-bottom: $toolbox_height; // for toolbox reserved space

            // overflow: hidden auto;

            .feature_info_container_inner {
                width: 100vw; // overwite width to fill screen in mobile display
                height: 100%;
                overflow: hidden auto;

                // height: $chart_size_vert; // FOR MULTIPLE CHARTS DELETE THIS
                // height: calc(#{$chart_size_vert} - #{$toolbox_height}); // FOR MULTIPLE CHARTS DELETE THIS

                .chart {
                    // width: 100%;
                    // height: $chart_size_vert;
                    // max-height: $chart_size_vert;
                }
            }
        }
    }
}

/***********************************
    DESKTOP ONLY STYLING
 ***********************************/
$feature_container_width: $sidebar_width;
$feature_container_min_width: $sidebar_min_width;

@include desktop-only {
    #feature_info_wrapper {
        flex-direction: row;
        width: $feature_container_width;
        min-width: $feature_container_min_width;
        .feature_info_container {
            height: 100%;
            width: 100%;
            // max-width: 850px;

            overflow: hidden;

            .feature_info_container_inner {
                height: 100%;
                width: 100%;
                // .chart {}
            }

            // set starting width for enter/final for leave animations
            // &.visual_container-enter,
            // &.visual_container-leave-to{
            //     width: 0;
            //     height: 100%;
            // }
        }
    }
}
</style>
