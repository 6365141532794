/**
 * Model to handle interface states for displaying status texts and show/hiding containers
 */
//  import axios from "axios";

const state = {
    status: null,
    drawer_expanded: false,
    chart_available: false,

    use_mobile_layout: false,

    selected_base_layer: null,
    base_layers: [],

    layers: [],

    visuals: null,
    // I think these need to happen simultaniously like a prefab object with both
    selected_layer: null,
    selected_feature: null,
}

const getters = {
    hasStatus: (model) => model.status != null,
    getStatus: (model) => model.status,

    hasLayers: (model) => {
        return model.layers.length > 0
    },
    getLayers: (model) => {
        return model.layers
    },

    getSelectedBaseLayer: (model) => model.selected_base_layer,
    getBaseLayers: (model) => model.base_layers,

    getSelectedLayer: (model) => model.selected_layer,
    getSelectedFeature: (model) => model.selected_feature,

    useMobileLayout: (model) => model.use_mobile_layout,

    hasVisuals: (model) => {
        return model.visuals != null && model.visuals.length > 0
    },
    getVisuals: (model) => model.visuals,
    isChartAvailable: (model) => model.chart_available,
    isDrawerExpanded: (model) => model.drawer_expanded,
}

const actions = {
    /**
     * set the status. expects either a string or an object containing { text , warning } attributes
     */
    setStatus({ commit }, new_status) {
        if (typeof new_status == "string") {
            commit("updateStatus", { text: new_status, warning: false })
        } else {
            commit("updateStatus", new_status)
        }
    },

    clearStatus({ commit }) {
        commit("updateStatus", null)
    },

    setMobileLayout({ commit }, val) {
        commit("updateMobileLayout", val)
    },

    setDrawerExpansion({ commit }, updated_value) {
        commit("updateDrawerExpansion", updated_value)
    },
    setChartAvailable({ commit }, updated_value) {
        commit("updateChartAvailable", updated_value)
    },

    setSelectedBaseLayer({ commit }, updated_value) {
        commit("updateSelectedBaseLayer", updated_value)
    },

    setBaseLayers({ commit }, updated_value) {
        commit("updateBaseLayers", updated_value)
    },

    removeLayers({ commit, state }, layer_ids) {
        let updated_collection

        // console.log('removing layers:')
        // console.log(layer_ids)

        if (layer_ids instanceof Array)
            updated_collection = state.layers.filter(
                (layer) => layer_ids.indexOf(layer.uid) == -1
            )
        else
            updated_collection = state.layers.filter(
                (layer) => layer.uid != layer_ids
            )

        commit("updateLayers", updated_collection)
    },

    addLayers({ commit, state }, layers) {
        let current_layers = state.layers

        commit("updateLayers", [
            ...current_layers,
            ...(layers instanceof Array ? layers : [layers]),
        ])
    },

    setLayers({ commit }, layers) {
        commit("updateLayers", layers)
    },

    setSelectedLayer({ commit }, layer_id) {
        commit("updateSelectedLayer", layer_id)
    },
    setSelectedFeature({ commit }, feature_id) {
        commit("updateSelectedFeature", feature_id)
    },

    setVisuals({ commit }, visuals_collection) {
        commit("updateVisuals", visuals_collection)
    },
}

const mutations = {
    updateStatus: (state, new_status) => (state.status = new_status),
    updateDrawerExpansion: (state, new_value) =>
        (state.drawer_expanded = new_value),
    updateChartAvailable: (state, new_value) =>
        (state.chart_available = new_value),
    clearStatus: (state) => (state.status = null),

    updateMobileLayout: (state, new_val) => (state.use_mobile_layout = new_val),

    updateSelectedBaseLayer: (state, new_val) =>
        (state.selected_base_layer = new_val),
    updateBaseLayers: (state, new_val) => (state.base_layers = new_val),

    updateLayers: (state, layers) => (state.layers = layers),
    updateVisuals: (state, new_visuals) => (state.visuals = new_visuals),

    updateSelectedLayer: (state, layer_id) => (state.selected_layer = layer_id),
    updateSelectedFeature: (state, feature_id) =>
        (state.selected_feature = feature_id),
}

export default {
    state,
    getters,
    actions,
    mutations,
}
